import styled from "styled-components/macro";
import { rem } from "@mc/components/core/styles";
import { fontSize16, fontSize16LineHeight, ListStyled } from "@mc/components/core/typography.styles";
import { black, green, red, tan, white } from "@mc/components/core/colours.styles";

type StatusListProps = {
  $isNegative: boolean;
};
export const StatusList = styled(ListStyled)<StatusListProps>`
  padding: 0;
  svg {
    fill: ${({ $isNegative }) => ($isNegative ? red : green)};
  }
`;

export const ApprovalSection = styled.div`
  position: fixed;
  background-color: ${white};
  bottom: 0;
  box-shadow: 0px -4px 5px 0px rgb(0 0 0 / 10%);
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(80)};
  z-index: 10;
`;

export const StepsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
`;

export const Step = styled.div`
  display: flex;
`;

type StepsLineProps = {
  borderType: "solid" | "dotted" | "none";
};
export const StepsLine = styled.div<StepsLineProps>`
  //intended spacing is 12. using 11 instead as border takes 2px not 1
  margin-right: ${rem(11)};
  display: flex;

  > div {
    box-sizing: border-box;
    width: ${rem(20)};
    height: ${rem(20)};
    border: 2px solid ${black};
    border-radius: 50%;
    margin-bottom: ${rem(24)};
  }

  > span {
    //applying a negative value to push line backwards for visual design
    margin: ${rem(20)} 0 0 ${rem(-11)};
    padding-left: ${rem(12)};
    border-left: ${rem(2)} ${({ borderType }) => borderType} ${black};
  }
`;

export const LikedListItem = styled.li`
  font-size: ${fontSize16};
  line-height: ${fontSize16LineHeight};

  display: grid;
  grid-template-columns: ${rem(16)} 1fr;
  align-items: start;
  gap: ${rem(12)};
`;

export const ExamplePropertyImage = styled.img`
  width: 100%;
`;

export const Header = styled.div`
  background-color: ${tan};
  padding: ${rem(40)} 0 ${rem(20)} 0;
`;
