import { generatePath, useNavigate } from "react-router-dom";
import useGamePlan from "game-plan/use-game-plan";
import { PropertyCardList } from "@mc/common/blocks/property-cards";
import { GAME_PLAN_EXISTING_PROPERTY } from "routing/routes";
import { Stack } from "@mc/components/stack/stack.styles";
import { NotificationInlineStyled } from "@mc/components/notification/notification.style";
import { SubheadLabel } from "@mc/components/core/typography.styles";
import { MdOutlineCottage } from "react-icons/md";

const GamePlanExistingProperties = () => {
  const navigate = useNavigate();
  const { profile } = useGamePlan();

  const hasExistingProperties = !!profile?.existingProperties?.length;

  return (
    <>
      <Stack direction="horizontal" spacing={10} marginBottom={24}>
        <MdOutlineCottage size={24} />
        <SubheadLabel marginBottom={0}>MY PROPERTIES</SubheadLabel>
      </Stack>

      {hasExistingProperties ? (
        <Stack spacing={16} as="ul">
          {profile?.existingProperties?.map((property) => (
            <PropertyCardList
              key={property._id}
              bedrooms={property.details?.coreAttributes.beds}
              bathrooms={property.details?.coreAttributes.baths}
              carSpaces={property.details?.coreAttributes.carSpaces}
              thumbnail={property.details?.images.defaultImage.mediumPhotoUrl}
              addressFormatted={property.details?.location.singleLine}
              onClick={() =>
                navigate(
                  generatePath(GAME_PLAN_EXISTING_PROPERTY, {
                    id: property._id || "",
                  })
                )
              }
            />
          ))}
        </Stack>
      ) : (
        <NotificationInlineStyled isPlaceholder fullWidth marginBottom={0}>
          Your property portfolio is currently empty.
        </NotificationInlineStyled>
      )}
    </>
  );
};

export default GamePlanExistingProperties;
