import { Margin } from "@mc/components/core/spacing.styles";
import { H3 } from "@mc/components/core/typography.styles";
import { DataList } from "@mc/components/tables/data-list";
import TooltipInfoModal from "@mc/components/tooltips/tooltip-info-modal";
import {
  PurchaseProjectDetailsDaisyFragmentFragment,
  PurchaseProjectDetailsEventFragmentFragment,
} from "@mc/common/gql/types";
import { PROJECT_DETAILS_HELP_CONTENT } from "./project-details.constants";
import { renovationTypeMap } from "../../../utils/enum-maps";

type Props = {
  event: PurchaseProjectDetailsEventFragmentFragment | null | undefined;
  daisy: PurchaseProjectDetailsDaisyFragmentFragment | null | undefined;
};
const ProjectDetails = ({ event, daisy }: Props) => {
  const projectYearString = event?.startAt.year;

  const foundDaisyProperty = (daisy?.properties ?? []).find((property) => property.propertyName === event?.label);

  if (!foundDaisyProperty) return null;

  const projectYearIndex = foundDaisyProperty.year.findIndex((y) => y.toString() === projectYearString);

  if (projectYearIndex === undefined || projectYearIndex === -1) return null;

  return (
    <div>
      <H3 marginBottom={12}>
        Project details <TooltipInfoModal text={PROJECT_DETAILS_HELP_CONTENT} />
      </H3>

      <Margin bottom={40}>
        <DataList
          data={[
            {
              label: "Project type",
              value: event?.project?.type ? renovationTypeMap[event.project.type].label : "N/A",
            },
            { label: "Project start date", value: event?.startAt.monthAndYear },
            {
              label: "Project budget",
              value: event?.project?.budgetWithCPI?.formatted ?? event?.project?.budget.formatted,
            },
            { label: "Loan", value: event?.project?.loan?.originalAmount?.formatted },
            { label: "Deposit", value: event?.project?.depositAmount.formatted },
            { label: "Post-project appraisal", value: foundDaisyProperty.marketValue[projectYearIndex + 1].formatted },
            {
              label: "Post-project rent (annual)",
              value: foundDaisyProperty.rentReceived[projectYearIndex + 1].formatted,
            },
            { label: "Assumed R.O.I.", value: event?.project?.assumedROI.formatted },
          ]}
        />
      </Margin>
    </div>
  );
};

export default ProjectDetails;
