import { gql } from "@apollo/client";
import {
  PURCHASE_CASH_FLOW_DAISY_FRAGMENT,
  PURCHASE_CASH_FLOW_EVENT_FRAGMENT,
} from "@mc/common/blocks/daisy/cash-flow-property/cash-flow-property.gql";
import {
  PURCHASE_PROJECT_DETAILS_DAISY_FRAGMENT,
  PURCHASE_PROJECT_DETAILS_EVENT_FRAGMENT,
} from "@mc/common/blocks/purchase/project-details/project-details.gql";
import { PURCHASE_DETAILS_FRAGMENT } from "@mc/common/blocks/purchase/purchase-details/purchase-details.gql";
import {
  PURCHASE_LOAN_ADJUSTMENT_DAISY_FRAGMENT,
  PURCHASE_LOAN_ADJUSTMENT_DETAILS_FRAGMENT,
} from "@mc/common/blocks/purchase/loan-adjustment-details/loan-adjustment-details.gql";

const TIMELINE_GAME_PLAN_OPTION_FRAGMENT = gql`
  fragment TimelineGamePlanOptionFragment on GamePlanOption {
    _id
    events {
      _id
      ...PurchaseCashFlowEventFragment
      property {
        _id
        description
        firstProjectEvent {
          ...PurchaseProjectDetailsEventFragment
        }
        ...PurchaseDetailsFragment
      }
      ...PurchaseProjectDetailsEventFragment
      ...PurchaseLoanAdjustmentDetailsFragment
    }
    daisy {
      ...PurchaseCashFlowDaisyFragment
      ...PurchaseLoanAdjustmentDaisyFragment
      ...PurchaseProjectDetailsDaisyFragment
    }
  }
  ${PURCHASE_CASH_FLOW_DAISY_FRAGMENT}
  ${PURCHASE_CASH_FLOW_EVENT_FRAGMENT}
  ${PURCHASE_DETAILS_FRAGMENT}
  ${PURCHASE_PROJECT_DETAILS_EVENT_FRAGMENT}
  ${PURCHASE_PROJECT_DETAILS_DAISY_FRAGMENT}
  ${PURCHASE_LOAN_ADJUSTMENT_DETAILS_FRAGMENT}
  ${PURCHASE_LOAN_ADJUSTMENT_DAISY_FRAGMENT}
`;
export const GET_TIMELINE_EVENTS = gql`
  query GetTimelineEvents {
    me {
      _id
      gamePlan {
        _id
        options {
          ...TimelineGamePlanOptionFragment
        }
        chosenOption {
          ...TimelineGamePlanOptionFragment
        }
      }
    }
  }
  ${TIMELINE_GAME_PLAN_OPTION_FRAGMENT}
`;
