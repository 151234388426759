import { H4, PStyled } from "@mc/components/core/typography.styles";

export const PURCHASE_DETAILS_HELP_CONTENT = (
  <>
    <PStyled>
      This table breaks down all of the costs related to buying this property based on our teams initial price
      appraisal. In each instance, we show you what was modelled in your Game Plan for easy comparison.
    </PStyled>

    <H4 marginBottom={0}>Purchase budget</H4>
    <PStyled>
      At this stage, it is a high-level appraisal of this property and all of the figures subsequent to this are
      relevant to the purchase budget stipulated.
    </PStyled>

    <H4 marginBottom={0}>Loan</H4>
    <PStyled>This is the assumed loan amount required for this property at this purchase price budget.</PStyled>

    <H4 marginBottom={0}>Deposit</H4>
    <PStyled>This figure represents what you will need to contribute towards the purchase price.</PStyled>

    <H4 marginBottom={0}>Purchase fees</H4>
    <PStyled>
      This figure represents additional purchase fees associated with the purchase, such as stamp duty, conveyancing and
      Milk Chocolate as an example. You can see the full breakdown by tapping &apos;Purchase fees&apos;.
    </PStyled>

    <H4 marginBottom={0}>Funds required</H4>
    <PStyled>
      This is the total funds required from you to contribute to the purchase, inclusive of the deposit and purchase
      fees.
    </PStyled>
  </>
);
