import { PurchaseTrackerItemStatus } from "@mc/common/gql/types";
import { StepAccordionToggle, StepItem, StepList } from "@mc/components/step-list/step-list";
import { AnchorOriginalStyled } from "../core/typography.styles";
import { Stage } from "./timeline.constants";
import { getSearchTrackerTimelineLink } from "./timeline.utils";

export type Props = {
  stages: Stage[];
  purchaseId: string;
  isAdmin: boolean;
  defaultExpanded: boolean;
};

const SearchTrackerTimeline = ({ stages, purchaseId, isAdmin, defaultExpanded }: Props) => (
  <>
    {stages?.map((stage) => (
      <StepAccordionToggle
        defaultExpanded={defaultExpanded}
        key={stage.label}
        title={`Stage ${stage?.number} - ${stage?.label}`}
        hasBorderBottom={false}
      >
        <StepList>
          {stage.items.map((item) => (
            <StepItem
              key={item.type}
              purchaseTrackerItemStatus={item.status}
              extraText={
                item.status === PurchaseTrackerItemStatus.ACTIVE_REQUIRED_ACTION
                  ? isAdmin
                    ? "Client action required"
                    : "Action required"
                  : ""
              }
            >
              {item.published || isAdmin ? (
                <AnchorOriginalStyled
                  $fontWeight="normal"
                  href={getSearchTrackerTimelineLink(isAdmin, purchaseId, item.slug)}
                >
                  {item.label}
                </AnchorOriginalStyled>
              ) : (
                item.label
              )}
            </StepItem>
          ))}
        </StepList>
      </StepAccordionToggle>
    ))}
  </>
);

export default SearchTrackerTimeline;
