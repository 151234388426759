import { H4, PStyled } from "@mc/components/core/typography.styles";

export const CASH_FLOW_HELP_TEXT = (
  <>
    <PStyled>
      This table details the results across key markers for your game plan in the year selected. Below, we define what
      each indicator represents for further clarity.
    </PStyled>

    <H4 marginBottom={0}>Rent</H4>
    <PStyled>
      This is our rental appraisal for the selected year, which you have the ability to switch between monthly and
      annual at the bottom of this table.
    </PStyled>

    <H4 marginBottom={0}>Holding costs</H4>
    <PStyled>
      This details all of the assumed expenses relating to this property, excluding your loan repayments, which we have
      separated.
    </PStyled>

    <H4 marginBottom={0}>Loan repayments</H4>
    <PStyled>
      This represents your loan repayments based on the loan type (variable vs. fixed), repayment type (interest only
      vs. principal & interest) and loan term (years) that we have suggested for this property.
    </PStyled>

    <H4 marginBottom={0}>Cash flow (exc. buffers)</H4>
    <PStyled>
      This represents the amount of money you have left over after deducting all the expenses and loan repayments from
      your income. This figure is your best case scenario and assumes buffer has been used.
    </PStyled>

    <H4 marginBottom={0}>Buffers</H4>
    <PStyled>
      This figure represents a recommended allowance for maintenance and vacant periods without rent. We apply a
      percentage of the property value for maintenance and vacancy buffers are determined by the asset type, location
      and vacancy rates.
    </PStyled>

    <H4 marginBottom={0}>Cash flow (inc. buffers)</H4>
    <PStyled>
      This allows for higher than expected periods where the property would sit vacant each year, likewise, an assumed
      fixed percentage of the value of the property that goes towards maintenance works each year.{" "}
    </PStyled>

    <H4 marginBottom={0}>Gross rental yield</H4>
    <PStyled>
      This figure represents your total income divided by the property value (of the year selected). It excludes any
      expenses and therefore classified as a gross rental yield.
    </PStyled>
  </>
);
