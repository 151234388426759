import styled, { css } from "styled-components/macro";
import { rem } from "../core/styles";
import {
  darkGrey,
  green,
  highlight,
  white,
  lightPink,
  tan,
  red,
  scaleLightGreen,
  scaleYellow,
  scaleGreen,
  scaleRed,
  scaleOrange,
  black,
} from "../core/colours.styles";
import { fontWeightMedium } from "../core/typography.styles";

type colour =
  | "pink"
  | "grey"
  | "tan"
  | "orange"
  | "green"
  | "red"
  | "scaleGreen"
  | "scaleLightGreen"
  | "scaleYellow"
  | "scaleRed"
  | "scaleOrange"
  | "black";

const colours = {
  red,
  pink: lightPink,
  green,
  grey: darkGrey,
  tan,
  orange: highlight,
  scaleGreen: scaleGreen,
  scaleLightGreen: scaleLightGreen,
  scaleYellow: scaleYellow,
  scaleRed: scaleRed,
  scaleOrange: scaleOrange,
  black: black,
};

export type BadgeProps = {
  colour?: colour;
  isSecondary?: boolean;
};

const baseBadge = css`
  border-radius: 2px;
  background-color: ${white};
  border: 1px solid ${highlight};
  color: ${highlight};
  display: inline-flex;
  font-weight: ${fontWeightMedium};
  font-size: ${rem(14)};
  line-height: initial;
  padding: ${rem(4)} ${rem(6)} ${rem(2)} ${rem(6)};
`;

export const Badge = styled.div<BadgeProps>`
  ${baseBadge}

  ${({ colour, isSecondary }) =>
    colour &&
    !isSecondary &&
    `
    background-color: ${colours[colour]};
    border-color: ${colours[colour]};
    color: ${white};
  `}

  ${({ colour, isSecondary }) =>
    colour &&
    isSecondary &&
    `
    background-color: ${white};
    border-color: ${colours[colour]};
    color: ${colours[colour]};
  `}
`;
