import { scoreBadge } from "@mc/common/utils";
import { Badge } from "@mc/components/badge/badge.styles";
import { CellContext, Row } from "@tanstack/react-table";
import { isNumber } from "lodash";

interface PercentageFragment {
  __typename?: "Percentage" | undefined;
  formatted: string;
  value: number;
}

interface MoneyFragment {
  __typename?: "Money" | undefined;
  formatted: string;
  amount: number;
}

interface BadgeFilterProps {
  value: string;
  columnValue: number;
}

export function percentageSortingFn<T>(rowA: Row<T>, rowB: Row<T>, columnId: string): number {
  return (
    ((rowA.getValue(columnId) as PercentageFragment)?.value ?? 0) -
    ((rowB.getValue(columnId) as PercentageFragment)?.value ?? 0)
  );
}

export function moneySortingFn<T>(rowA: Row<T>, rowB: Row<T>, columnId: string): number {
  return (
    ((rowA.getValue(columnId) as MoneyFragment)?.amount ?? 0) -
    ((rowB.getValue(columnId) as MoneyFragment)?.amount ?? 0)
  );
}

export function moneyOrPercentageCellAccessor<T>(
  cellContext: CellContext<T, MoneyFragment | PercentageFragment | null> | null
): string | null {
  if (!cellContext) return null;
  return cellContext.getValue()?.formatted ?? null;
}

export function bryceScoreBadgeCellAccessor<T>(cellContext: CellContext<T, number>) {
  if (!cellContext) return null;
  return isNumber(cellContext.getValue()) ? (
    <Badge {...scoreBadge.getBryceScoreBadge(cellContext.getValue()!).badgeProps}>
      {scoreBadge.getBryceScoreBadge(cellContext.getValue()).label}
    </Badge>
  ) : null;
}

export function mcScoreBadgeCellAccessor<T>(cellContext: CellContext<T, number>) {
  if (!cellContext) return null;
  return isNumber(cellContext.getValue()) ? (
    <Badge {...scoreBadge.getMCScoreBadge(cellContext.getValue()!).badgeProps}>
      {scoreBadge.getMCScoreBadge(cellContext.getValue()).label}
    </Badge>
  ) : null;
}

const badgeFilter = ({ columnValue, value }: BadgeFilterProps) =>
  (columnValue > 0.25 && value === "Average and above") ||
  (columnValue > 0.5 && value === "Good and above") ||
  (columnValue > 0.75 && value === "Excellent and above");

export function scoreBadgeFilteringFn<T>(row: Row<T>, columnId: string, value: string): boolean {
  const columnValue: number = row.getValue(columnId);
  return columnValue ? badgeFilter({ value, columnValue }) : false;
}
