import { gql } from "@apollo/client";
import { PURCHASE_CASH_FLOW_DAISY_FRAGMENT } from "@mc/common/blocks/daisy/cash-flow-property/cash-flow-property.gql";
import { PURCHASE_CASH_FLOW_SUMMARY_DAISY_FRAGMENT } from "@mc/common/blocks/daisy/cash-flow-summary/cash-flow-summary.gql";
import { CAPITAL_POSITION_PROPERTY_FRAGMENT } from "@mc/common/blocks/daisy/capital-position-property/capital-position-property.gql";
import { CAPITAL_POSITION_SUMMARY_FRAGMENT } from "@mc/common/blocks/daisy/capital-position-summary/capital-position-summary.gql";
import { TIMELINE_FRAGMENT } from "shared/graphql/timeline.graphql";
import { PREFERRED_OPTION_FRAGMENT } from "@mc/common/blocks/game-plan-feedback/preferred-option/preferred-option.gql";
import { GAME_PLAN_FEEDBACK_CONTRIBUTIONS_PROFILE_FRAGMENT } from "@mc/common/blocks/game-plan-feedback/contributions/contributions.gql";
import { GAME_PLAN_FEEDBACK_HOME_PURCHASE_DETAILS_PROFILE_FRAGMENT } from "@mc/common/blocks/game-plan-feedback/home-purchase-details/home-purchase-details.gql";

const MONEY_CLIENT_FRAGMENT = gql`
  fragment MoneyClientFragment on Money {
    __typename
    formatted
    amount
    isNegative
  }
`;

const PERCENTAGE_FRAGMENT = gql`
  fragment PercentageFragment on Percentage {
    __typename
    formatted
    value
  }
`;

const OPTION_FRAGMENT = gql`
  fragment GamePlanOptionFragment on GamePlanOption {
    _id
    ...PreferredOptionFragment
    description
    summary
    tags
    readByClient
    publishedAt {
      formatted(format: "DD MMM YYYY")
    }
    timeline {
      items {
        ...TimelineFragment
      }
    }
    daisy {
      _id
      ...PurchaseCashFlowDaisyFragment
      ...PurchaseCashFlowSummaryDaisyFragment
      ...CapitalPositionPropertyFragment
      ...CapitalPositionSummaryFragment
      properties {
        _id
        totalEquityList {
          amount
        }
      }
      summary {
        _id
        totalEquity {
          ...MoneyClientFragment
        }
        ifSoldRoi {
          ...PercentageFragment
        }
        totalAvailableEquityToRedraw {
          ...MoneyClientFragment
        }
        assumedBankAppraisal {
          ...MoneyClientFragment
        }
        assumedMarketAppraisal {
          ...MoneyClientFragment
        }
        debt {
          ...MoneyClientFragment
        }
        lvr {
          ...PercentageFragment
        }
        holdingCost {
          ...MoneyClientFragment
        }
        roi {
          formatted
          value
        }
      }
    }
  }
  ${PREFERRED_OPTION_FRAGMENT}
  ${MONEY_CLIENT_FRAGMENT}
  ${PERCENTAGE_FRAGMENT}
  ${TIMELINE_FRAGMENT}
  ${PURCHASE_CASH_FLOW_DAISY_FRAGMENT}
  ${PURCHASE_CASH_FLOW_SUMMARY_DAISY_FRAGMENT}
  ${CAPITAL_POSITION_PROPERTY_FRAGMENT}
  ${CAPITAL_POSITION_SUMMARY_FRAGMENT}
`;

export const GET_GAME_PLAN_CLIENT = gql`
  query GetGamePlanClient($clientId: String!) {
    gamePlan(clientId: $clientId) {
      _id
      status
      description
      currentRoundNumber
      options {
        ...GamePlanOptionFragment
      }
      chosenOption {
        timeline {
          items {
            events {
              property {
                _id
              }
            }
          }
        }
      }
      publishedRounds {
        round
        options {
          ...GamePlanOptionFragment
        }
      }
    }
    client(id: $clientId) {
      _id
      profile(status: ACTIVE) {
        _id
        ...GamePlanFeedbackContributionsProfileFragment
        ...GamePlanFeedbackHomePurchaseDetailsProfileFragment
        createdAt {
          year: formatted(format: "YYYY")
        }
        updatedAt {
          formatted(format: "YYYY")
        }
        services
        existingProperties {
          _id
          equityToUse {
            amount
          }
          address {
            formatted(country: false)
          }
          details {
            id
            coreAttributes {
              beds
              baths
              carSpaces
              propertyType
            }
            location {
              singleLine
            }
            images {
              defaultImage {
                mediumPhotoUrl
              }
            }
          }
        }
      }
    }
  }
  ${OPTION_FRAGMENT}
  ${GAME_PLAN_FEEDBACK_CONTRIBUTIONS_PROFILE_FRAGMENT}
  ${GAME_PLAN_FEEDBACK_HOME_PURCHASE_DETAILS_PROFILE_FRAGMENT}
`;

export const CHOOSE_GAME_PLAN_OPTION = gql`
  mutation ChooseGamePlanOption($gamePlanOptionId: String!) {
    chooseGamePlanOption(gamePlanOptionId: $gamePlanOptionId) {
      ...GamePlanOptionFragment
    }
  }
  ${OPTION_FRAGMENT}
`;
