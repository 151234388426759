import { Modal } from "@mc/components/modal/modal";
import { GET_TIMELINE_EVENTS } from "./timeline.graphql";
import { useQuery } from "@apollo/client";
import { H1 } from "@mc/components/core/typography.styles";
import ProjectDetails from "@mc/common/blocks/purchase/project-details/project-details";
import { Bouncer } from "@mc/components/bouncer/bouncer";
import { GetTimelineEventsQuery } from "@mc/common/gql/types";

type Props = {
  selectedEventId: string | null;
  close: () => void;
  optionId?: string;
};

const TimelineProjectModal = ({ selectedEventId, close, optionId }: Props) => {
  const { data, loading } = useQuery<GetTimelineEventsQuery>(GET_TIMELINE_EVENTS, { skip: !selectedEventId });

  const selectedOption = optionId
    ? data?.me.gamePlan?.options.find((option) => option._id === optionId)
    : data?.me.gamePlan?.chosenOption;

  const selectedEvent = selectedOption?.events.find((event) => event._id === selectedEventId);

  if (loading)
    return (
      <Modal isVisible close={close}>
        <Bouncer />
      </Modal>
    );

  return (
    <Modal isVisible close={close}>
      <H1>Project brief</H1>

      <ProjectDetails event={selectedEvent} daisy={selectedOption?.daisy} />
    </Modal>
  );
};

export default TimelineProjectModal;
