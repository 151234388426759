import styled, { css } from "styled-components/macro";

import { rem } from "../core/styles";
import { darkGrey, lightGrey, white, black, tan } from "../core/colours.styles";
import {
  fontSize14,
  fontSize16,
  fontSize14LineHeight,
  fontSize16LineHeight,
  fontWeightNormal,
  fontWeightMedium,
} from "../core/typography.styles";
import { Badge } from "../badge/badge.styles";

export const TimelineLabel = styled.div`
  background-color: ${lightGrey};
  border-radius: ${rem(50)};
  color: ${darkGrey};
  font-size: ${fontSize14};
  text-align: center;
  padding: ${rem(4)};
  margin-bottom: ${rem(12)};
`;

export const TimelineYear = styled.div`
  color: ${darkGrey};
  font-size: ${fontSize14};
  text-align: left;
  margin-bottom: ${rem(16)};
`;

export const TimelineEventSmallTitle = styled.div`
  color: ${black};
  font-size: ${fontSize14};
  font-weight: ${fontWeightNormal};
  line-height: ${fontSize14LineHeight};
`;

export const TimelineEventTitle = styled.div`
  color: ${black};
  font-size: ${fontSize16};
  font-weight: ${fontWeightMedium};
  line-height: ${fontSize16LineHeight};
`;

const baseTimelineEvent = css`
  margin-bottom: ${rem(12)};
  background-color: ${white};
  border: 1px solid ${lightGrey};
  display: block;
  color: ${black};
  position: relative;
  border-radius: ${rem(4)};
  width: 100%;
  text-align: left;

  & > :nth-child(1) {
    padding: ${rem(12)} ${rem(16)} ${rem(12)} ${rem(16)};
  }
`;

export const TimelineEventContainer = styled.div`
  ${baseTimelineEvent}
`;

export const TimelineEventMainCard = styled.div`
  cursor: pointer;
`;

export const TimelineEventGrey = styled.button.attrs({
  type: "button",
})`
  ${baseTimelineEvent}
  background-color: ${white};
  border: 2px solid ${lightGrey};
`;

export const TimelineEventTan = styled.button.attrs({
  type: "button",
})`
  ${baseTimelineEvent}
  background-color: ${white};
  border: 2px solid ${tan};
`;

export const TimelineEventStatus = styled(Badge)`
  position: absolute;
  right: ${rem(12)};
  top: ${rem(12)};
`;

export const TimelineExpandable = styled.div`
  overflow: hidden;
`;

export const TimelineExpandableToggle = styled.div`
  padding-top: ${rem(32)};
`;

type TimelineEventProps = {
  colour: string;
};

export const TimelineIcon = styled.div<TimelineEventProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${rem(48)};
  width: ${rem(48)};
  border-radius: 50%;
  background: ${({ colour }) => colour};
`;

export const PurchaseTrackerTimelineContainer = styled.div`
  background-color: ${white};
  margin-bottom: ${rem(40)};
`;

export const SingleLine = styled.div`
  &:before {
    content: "";
    display: inline-block;
    width: ${rem(12)};
    height: ${rem(12)};
    background-color: ${black};
    border-radius: 50%;
    margin: 0 ${rem(8)};
  }
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: ${rem(16)};
`;
