import { AnchorStyled, H4, PStyled } from "@mc/components/core/typography.styles";
import { Col, Container, Row } from "@mc/components/grid/grid.styles";
import { GET_IN_TOUCH_PAGE } from "routing/routes";
import { GetInTouchContainer } from "./get-in-touch-footer.styles";

const GetInTouchFooter = () => {
  return (
    <GetInTouchContainer>
      <Container>
        <Row centerXs>
          <Col md={6}>
            <H4 marginBottom={12}>Need help?</H4>

            <PStyled marginBottom={0}>
              For any questions or concerns, contact our customer services team.{" "}
              <AnchorStyled to={GET_IN_TOUCH_PAGE}>Get in touch</AnchorStyled>
            </PStyled>
          </Col>
        </Row>
      </Container>
    </GetInTouchContainer>
  );
};

export default GetInTouchFooter;
