import styled from "styled-components";
import { darkGrey, lightGrey, white } from "../core/colours.styles";
import { SpacingValue } from "../core/spacing.styles";
import { rem } from "../core/styles";
import { fontSize14 } from "../core/typography.styles";

type DividerProps = {
  marginBottom?: SpacingValue;
  text?: string;
};

export const Divider = styled.div<DividerProps>`
  width: 100%;
  height: 1px;
  background-color: ${lightGrey};
  margin-bottom: ${({ marginBottom }) => rem(marginBottom ?? 0)};
  display: flex;
  flex-direction: column;
  align-items: center;

  ::after {
    content: ${({ text }) => (text ? `'${text}'` : "")};

    font-size: ${fontSize14};
    color: ${darkGrey};
    position: relative;
    top: ${rem(-10)};
    background-color: ${white};
    padding: 0 ${rem(8)};
  }
`;
