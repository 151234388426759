import { useEffect, useMemo, useState } from "react";
import { CenterAlign, Margin } from "@mc/components/core/spacing.styles";
import { DataList } from "@mc/components/tables/data-list";
import { useTabState } from "@mc/components/tabs/tabs.hooks";
import { Tabs } from "@mc/components/tabs/tabs";
import { TabOption } from "@mc/components/tabs/tabs.constants";
import CashFlowHeading from "../cash-flow-heading/cash-flow-heading";
import { CASH_FLOW_HELP_TEXT } from "./cash-flow-property.constants";
import { PurchaseCashFlowDaisyFragmentFragment, PurchaseCashFlowEventFragmentFragment } from "@mc/common/gql/types";

type Props = {
  daisy: PurchaseCashFlowDaisyFragmentFragment | null | undefined;
  gamePlanEvent?: PurchaseCashFlowEventFragmentFragment | null | undefined;
  propertyName?: string | undefined;
  yearIndex?: number;
};

const CashFlowProperty = ({ gamePlanEvent, daisy, propertyName, yearIndex }: Props) => {
  const tabs = useMemo<TabOption[]>(
    () => [
      { label: "Monthly", value: "M" },
      { label: "Annual", value: "A" },
    ],
    []
  );

  const [selectedYear, setSelectedYear] = useState(0);

  const { tab, setTab } = useTabState(tabs[0]);

  const daisyProperties = daisy?.properties;

  const purchaseEvent = gamePlanEvent;

  const foundDaisyProperty = (daisyProperties ?? []).find(
    (property) => property.propertyName === purchaseEvent?.label || property.propertyName === propertyName
  );

  useEffect(() => {
    if (foundDaisyProperty && gamePlanEvent) {
      setSelectedYear(parseInt(gamePlanEvent.startAt.year));
    }
  }, [setSelectedYear, foundDaisyProperty, gamePlanEvent]);

  if (!foundDaisyProperty) return null;

  const purchaseYearIndex =
    yearIndex ?? foundDaisyProperty.year.findIndex((y) => y.toString() === selectedYear.toString());

  if (!tab || purchaseYearIndex === undefined || purchaseYearIndex === -1) return null;

  return (
    <div>
      <CashFlowHeading
        yearIndex={yearIndex}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        foundDaisyProperty={foundDaisyProperty}
        helpContent={CASH_FLOW_HELP_TEXT}
      />

      <Margin bottom={16}>
        <DataList
          data={[
            {
              label: "Rent",
              value: `${
                tab.value === "M"
                  ? foundDaisyProperty.monthlyRentReceived?.[purchaseYearIndex].formatted
                  : foundDaisyProperty.rentReceived?.[purchaseYearIndex].formatted
              } (${foundDaisyProperty.weeklyRentReceived?.[purchaseYearIndex].formatted} per week)`,
            },
            {
              label: "Holding costs",
              value:
                tab.value === "M"
                  ? foundDaisyProperty.monthlyHoldingCosts?.[purchaseYearIndex].formatted
                  : foundDaisyProperty.holdingCosts?.[purchaseYearIndex].formatted,
            },
            {
              label: "Loan repayments",
              value:
                tab.value === "M"
                  ? foundDaisyProperty.monthlyLoanCostsTotal?.[purchaseYearIndex].formatted
                  : foundDaisyProperty.loanCostsTotal?.[purchaseYearIndex].formatted,
            },
            {
              label: "Cash flow (exc. buffers)",
              value:
                tab.value === "M"
                  ? foundDaisyProperty.monthlyGrossIncomeBest?.[purchaseYearIndex].formatted
                  : foundDaisyProperty.grossIncomeBest?.[purchaseYearIndex].formatted,
            },
            {
              label: "Buffers",
              value:
                tab.value === "M"
                  ? foundDaisyProperty.monthlyWallet?.[purchaseYearIndex].formatted
                  : foundDaisyProperty.wallet?.[purchaseYearIndex].formatted,
              subValues: {
                defaultCollapsed: true,
                values: [
                  {
                    label: "Vacancy buffer",
                    value:
                      tab.value === "M"
                        ? foundDaisyProperty.monthlyVacancyBuffer?.[purchaseYearIndex].formatted
                        : foundDaisyProperty.vacancyBuffer?.[purchaseYearIndex].formatted,
                  },
                  {
                    label: "Maintenance buffer",
                    value:
                      tab.value === "M"
                        ? foundDaisyProperty.monthlyMaintenanceBuffer?.[purchaseYearIndex].formatted
                        : foundDaisyProperty.maintenanceBuffer?.[purchaseYearIndex].formatted,
                  },
                ],
              },
            },
            {
              label: "Cash flow (inc. buffers)",
              value:
                tab.value === "M"
                  ? foundDaisyProperty.monthlyGrossIncomeWorst?.[purchaseYearIndex].formatted
                  : foundDaisyProperty.grossIncomeWorst?.[purchaseYearIndex].formatted,
            },
            {
              label: "Gross rental yield",
              value: foundDaisyProperty.grossRentalYield?.[purchaseYearIndex].formatted,
            },
          ]}
        />
      </Margin>

      <CenterAlign>
        <Tabs
          options={tabs}
          activeTab={tab.value}
          ariaDescription={"Cash flow frequency"}
          contentPanelId={"cash-flow-tab"}
          onSelect={setTab}
          marginBottom={0}
        />
      </CenterAlign>
    </div>
  );
};

export default CashFlowProperty;
