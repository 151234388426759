import styled, { css } from "styled-components";
import { black, lightGrey, softGrey, white } from "../core/colours.styles";
import { rem, standardFocusState } from "../core/styles";
import { fontSize16 } from "../core/typography.styles";

export const Items = styled.ul`
  border-radius: ${rem(4)};
  background-color: ${white};
  background-clip: padding-box;
  border: 1px solid ${lightGrey};
  list-style: none;
  min-width: ${rem(80)};
  padding: 0;
  position: absolute;
  top: 80%;
  left: 0;
  float: left;
  margin-top: ${rem(12)};
  z-index: 1000;
`;

export const MenuButton = styled.button``;

type MenuContainerProps = {
  $withBorder?: boolean;
  noBackgroundColor?: boolean;
  shrink?: boolean;
};
export const MenuContainer = styled.div<MenuContainerProps>`
  max-width: 100%;
  position: relative;

  ${MenuButton} {
    background-color: ${white};
    border: ${({ $withBorder = true }) => ($withBorder ? `1px solid ${lightGrey}` : `none`)};
    border-radius: ${rem(40)};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${black};
    padding: 0;
    height: ${({ shrink }) => (shrink ? "auto" : rem(40))};
    width: ${({ shrink }) => (shrink ? "auto" : rem(40))};
    line-height: ${rem(40)};

    &:hover {
      background-color: ${lightGrey};
      cursor: pointer;
    }

    &[aria-expanded="true"] {
      background-color: ${lightGrey};
    }

    ${standardFocusState}

    ${({ noBackgroundColor }) =>
      noBackgroundColor &&
      `
      background-color: transparent !important;

      :hover {
        background-color: transparent;
      }
    `}
  }
`;

type ItemProps = {
  isFirstItem: boolean;
  isActive: boolean;
  disabled?: boolean;
  onClick: () => void;
};
export const Item = styled.li.attrs({ role: "menuitem" })<ItemProps>`
  background-color: ${white};
  border-radius: 0;
  border: none;
  display: block;
  font-size: ${fontSize16};
  padding: ${rem(12)} 0 ${rem(12)} 0;
  position: relative;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;

  ${({ isFirstItem }) =>
    isFirstItem &&
    `
      border-top: 1px solid ${lightGrey};
    `}

  &:first-child {
    border-radius: ${rem(4)} ${rem(4)} 0 0;
  }
  &:last-child {
    border-radius: 0 0 ${rem(4)} ${rem(4)};
  }

  span {
    margin-left: ${rem(12)};
    margin-right: ${rem(12)};
    font-size: ${fontSize16};
  }

  ${({ isActive, disabled }) => {
    if (disabled)
      return `
        opacity: 0.5;
        user-select: none;
      `;

    if (isActive) {
      return `
        background-color: ${black};
        color: ${white};
        cursor: pointer;
      `;
    }

    return css`
      &:hover,
      &:active {
        background-color: ${softGrey};
        color: ${black};
        cursor: pointer;
      }
    `;
  }}
`;
