import { PStyled, AnchorStyled } from "@mc/components/core/typography.styles";
import { FooterStyled } from "./footer.styles";
import { Stack } from "@mc/components/stack/stack.styles";

const Footer = () => (
  <FooterStyled>
    <Stack direction="horizontal" spacing={16}>
      <AnchorStyled to="https://milkchoc.com.au/terms-of-service">Terms of service</AnchorStyled>
      <PStyled marginBottom={0}>©2023 Milk Chocolate Pty Ltd.</PStyled>
    </Stack>
  </FooterStyled>
);

export default Footer;
