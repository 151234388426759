import { H4, PStyled } from "@mc/components/core/typography.styles";
import { UL } from "./project-details.styles";

export const PROJECT_DETAILS_HELP_CONTENT = (
  <>
    <PStyled>
      If your Game Plan stipulates that a project is included, now or in the future, we provide you with the high-level
      details related to that project.
    </PStyled>

    <H4 marginBottom={0}>Project type</H4>
    <PStyled>There are several types of projects that you can execute. These are, but not limited to:</PStyled>
    <UL>
      <li>
        <PStyled>
          Cosmetic—Generally any renovation that improves the appearance of a property without changing its size or
          improving its structural integrity.
        </PStyled>
      </li>
      <li>
        <PStyled>
          Structural—Generally, it involves moving walls and changing the property’s floor plan. As the name suggests,
          the structure of the home is altered to create a more liveable space.
        </PStyled>
      </li>
      <li>
        <PStyled>
          Granny flat—A self-contained housing unit located on the same block as a single dwelling. No sub-division of
          the block is required generally.
        </PStyled>
      </li>
      <li>
        <PStyled>
          Sub-division—This is an act of splitting one parcel of land into multiple lots and building new dwellings on
          the newly created blocks. This can be in the form of 2-lot, 3-lot, 4-lot etc. In some instances, there may be
          the ability to preserve the existing dwelling, sub-divide the block and build new a new dwelling(s), without
          the need to remove the original dwelling.
        </PStyled>
      </li>
      <li>
        <PStyled>
          New build—This is the demolition of the existing dwelling and building one brand new dwelling. Alternatively,
          buying a vacant block of land and building one new dwelling on this block.
        </PStyled>
      </li>
    </UL>

    <H4 marginBottom={0}>Project start date</H4>
    <PStyled>
      The modelling needs to assume a start date for the project as this will affect your cash contribution and
      cash-flow requirements. This date identifies when we are recommending the project to commence.
    </PStyled>

    <H4 marginBottom={0}>Project budget</H4>
    <PStyled>
      This is the assumed budget allocated for the project. Your buying team are aware of this and will be assessing all
      available properties noting this budget for any recommended works.
    </PStyled>

    <H4 marginBottom={0}>Loan</H4>
    <PStyled>
      If the modelling assumes you are borrowing funds for the project, this is the amount for the loan required.
    </PStyled>

    <H4 marginBottom={0}>Deposit</H4>
    <PStyled>
      This is your personal contribution for the project and is either on-top of the loan or exclusive funding if no
      loan has been modelled. This can either be your cash contribution, or equity drawn-down from existing assets.
    </PStyled>

    <H4 marginBottom={0}>Post-project appraisal</H4>
    <PStyled>
      We have made an assumption on the value of asset after the project has been completed. This figured is derived
      from the assumed R.O.I our economists have used in the modelling.
    </PStyled>

    <H4 marginBottom={0}>Post-project rent</H4>
    <PStyled>
      This is the assumed rental income you could potentially achieve once the project has been completed.
    </PStyled>

    <H4 marginBottom={0}>Assumed R.O.I</H4>
    <PStyled>
      Our economists make an assumption of the return on investment (R.O.I) which is based on the project type and
      market conditions.
    </PStyled>
  </>
);
