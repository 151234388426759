import { LengthUnit } from "@mc/common/gql/types";

type Coordinates = {
  latitude?: number;
  longitude?: number;
};

type Length = {
  value: number;
  unit: LengthUnit;
};

class Distance {
  length: Length;

  constructor(length: Length) {
    this.length = length;
  }

  format(): string {
    let multiplier: number;

    switch (this.length.unit) {
      case LengthUnit.METRE:
        multiplier = 1;
        break;

      case LengthUnit.KILOMETRE:
        multiplier = 1000;
        break;
    }

    const distanceInMetre = this.length.value * multiplier;

    const roundedDistanceInMetre = Math.round(distanceInMetre);

    const distanceInKilometre = distanceInMetre / 1000;

    if (roundedDistanceInMetre < 1000) {
      return `${roundedDistanceInMetre}m`;
    } else if (roundedDistanceInMetre < 10000) {
      const withOneDecimalPlace = Math.round(distanceInKilometre * 10) / 10;
      return `${withOneDecimalPlace}km`;
    } else if (roundedDistanceInMetre < 100000) {
      const roundedKilometre = Math.round(distanceInKilometre);
      return `${roundedKilometre}km`;
    } else {
      return "over 100km";
    }
  }

  static getDistance({ from, to }: { from?: Coordinates; to?: Coordinates }): Length | null {
    if (!from?.latitude || !from?.longitude || !to?.latitude || !to?.longitude) return null;

    const EARTH_RADIUS = 6371e3; // metres

    const φ1 = (from.latitude * Math.PI) / 180; // φ, λ in radians
    const φ2 = (to.latitude * Math.PI) / 180;

    const Δφ = ((to.latitude - from.latitude) * Math.PI) / 180;
    const Δλ = ((to.longitude - from.longitude) * Math.PI) / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = EARTH_RADIUS * c; // in metres

    return {
      value: d,
      unit: LengthUnit.METRE,
    };
  }
}

export default Distance;
