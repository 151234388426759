import { gql } from "@apollo/client";

export const CAPITAL_POSITION_PROPERTY_FRAGMENT = gql`
  fragment CapitalPositionPropertyFragment on Daisy {
    _id
    properties {
      _id
      propertyName
      ifSold {
        formatted
      }
      banksValuation {
        formatted
      }
      marketValue {
        formatted
      }
      debt {
        formatted
      }
      totalEquityList {
        formatted
      }
      equityAvailableToRedraw {
        formatted
      }
      lvr {
        formatted
      }
      fundsHeldInOffset {
        formatted
      }
      fundsAvailableAfterSale {
        formatted
      }
    }
  }
`;
