import { gql } from "@apollo/client";

export const PURCHASE_CASH_FLOW_DAISY_FRAGMENT = gql`
  fragment PurchaseCashFlowDaisyFragment on Daisy {
    _id
    properties {
      _id
      propertyName
      year
      weeklyRentReceived: rentReceived(frequency: WEEKLY) {
        formatted
      }
      monthlyRentReceived: rentReceived(frequency: MONTHLY) {
        formatted
      }
      rentReceived {
        formatted
      }
      monthlyHoldingCosts: holdingCosts(frequency: MONTHLY) {
        formatted
      }
      holdingCosts {
        formatted
      }
      monthlyWallet: wallet(frequency: MONTHLY) {
        formatted
      }
      wallet {
        formatted
      }
      monthlyVacancyBuffer: vacancyBuffer(frequency: MONTHLY) {
        formatted
      }
      vacancyBuffer {
        formatted
      }
      monthlyMaintenanceBuffer: maintenanceBuffer(frequency: MONTHLY) {
        formatted
      }
      maintenanceBuffer {
        formatted
      }
      monthlyLoanCostsTotal: loanCostsTotal(frequency: MONTHLY) {
        formatted
      }
      loanCostsTotal {
        formatted
      }
      monthlyGrossIncomeBest: grossIncomeBest(frequency: MONTHLY) {
        formatted
      }
      grossIncomeBest {
        formatted
      }
      monthlyGrossIncomeWorst: grossIncomeWorst(frequency: MONTHLY) {
        formatted
      }
      grossIncomeWorst {
        formatted
      }
      grossRentalYield {
        formatted
      }
    }
  }
`;

export const PURCHASE_CASH_FLOW_EVENT_FRAGMENT = gql`
  fragment PurchaseCashFlowEventFragment on GamePlanEvent {
    _id
    label
    startAt {
      year: formatted(format: "YYYY")
    }
  }
`;
