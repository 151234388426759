import { ReactNode } from "react";
import { lightPink, tan } from "@mc/components/core/colours.styles";

export const TAN = tan;
export const PINK = lightPink;

export type LayoutColour = typeof PINK | typeof TAN;

export type LayoutProps = {
  children: ReactNode;
  backgroundColor?: LayoutColour;
  header?: {
    backgroundColor?: LayoutColour;
    goBackTo?: string;
    goBack?: boolean;
  };
  isNormalFlowingPage?: boolean;
  hasFooter?: boolean;
  hasGetInTouchFooter?: boolean;
  paddingTop?: number;
  banner?: ReactNode;
};
