import { useCallback, useState, useEffect } from "react";
import { HEADER_HEIGHT } from "./header.styles";

export const useHeaderScroll = () => {
  const [scrolled, setScrolled] = useState<boolean>(false);

  const onScroll = useCallback(() => {
    setScrolled(window.pageYOffset > HEADER_HEIGHT);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
    //eslint-disable-next-line
  }, []);

  return {
    hasScrolled: scrolled,
  };
};
