import { Fragment, useState } from "react";

import TimelineEvent from "./timeline-event";
import * as Styled from "./timeline.styles";
import { ButtonLink } from "../buttons";
import { TimelineProps } from "./timeline.constants";

export const TimelineYear = ({ year }: { year: number }) => {
  return <Styled.TimelineYear>{year}</Styled.TimelineYear>;
};

export const Timeline = ({ timeline, onEventClick, isAdmin, fullView }: TimelineProps) => {
  console.log("timeline", timeline);
  const [isOpen, setIsOpen] = useState<boolean>(!!fullView || false);

  const fixedItems: Array<typeof timeline[number]> = timeline;

  return (
    <>
      <Styled.TimelineExpandable>
        {fixedItems
          .filter((_, index) => isOpen || index < 3)
          .map((item) => (
            <Fragment key={item.year}>
              <TimelineYear year={Number(item.year)} />
              {item.events.map((ev) => (
                <TimelineEvent key={ev._id} event={ev} onClick={onEventClick} isAdmin={isAdmin} />
              ))}
            </Fragment>
          ))}
      </Styled.TimelineExpandable>
      {!fullView && (
        <Styled.TimelineExpandableToggle>
          <ButtonLink type="button" onClick={() => setIsOpen(!isOpen)} $fontWeight="normal">
            {!isOpen ? "See all Game Plan events" : "Collapse Game Plan events"}
          </ButtonLink>
        </Styled.TimelineExpandableToggle>
      )}
    </>
  );
};
