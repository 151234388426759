import { CenterAlign, Margin } from "@mc/components/core/spacing.styles";
import { H1, PStyled } from "@mc/components/core/typography.styles";
import { Col, ColProportion, Container, Row } from "@mc/components/grid/grid.styles";
import { useEffect, useState } from "react";
import { HeroImageContainer, HeroSectionContainer } from "./hero-section.styles";

type HeroSectionProps = {
  title: string;
  paragraph?: string[];
  imgSrc?: string;
  imgAlt?: string;
  isRegister?: boolean;
  isSignIn?: boolean;
  marginTop?: number;
};

const HeroSection = ({ title, paragraph, imgSrc, imgAlt, isRegister, isSignIn, marginTop }: HeroSectionProps) => {
  const [mediumBreakpoint, setMediumBreakpoint] = useState<ColProportion>(6);
  const [largeBreakpoint, setLargeBreakpoint] = useState<ColProportion | undefined>(undefined);

  useEffect(() => {
    if (isRegister) {
      setMediumBreakpoint(8);
      setLargeBreakpoint(6);
    } else if (isSignIn) {
      setMediumBreakpoint(8);
      setLargeBreakpoint(4);
    } else {
      setMediumBreakpoint(6);
      setLargeBreakpoint(undefined);
    }
  }, [isRegister, isSignIn]);

  return (
    <HeroSectionContainer marginTop={marginTop}>
      <Container>
        <Row centerXs>
          <Col md={mediumBreakpoint} lg={largeBreakpoint}>
            <HeroImageContainer isRegister={isRegister}>
              <Margin bottom={12} lgBottom={16}>
                <H1 isPrimary marginBottom={0}>
                  {title}
                </H1>
              </Margin>

              {paragraph?.map((p, i) => (
                <PStyled key={i} marginBottom={24}>
                  {p}
                </PStyled>
              ))}

              {!!imgSrc ? (
                <CenterAlign>
                  <img src={imgSrc} alt={imgAlt} />
                </CenterAlign>
              ) : (
                <Margin bottom={40} />
              )}
            </HeroImageContainer>
          </Col>
        </Row>
      </Container>
    </HeroSectionContainer>
  );
};

export default HeroSection;
