import { PropertyType } from "@mc/common/gql/types";
import assertUnreachable from "./assert-unreachable";
import { propertyTypeMap } from "./enum-maps";

export type Brief = {
  budget: Budget;
  beds?: NumberRange | null | undefined;
  baths?: NumberRange | null | undefined;
  cars?: NumberRange | null | undefined;
  landArea?: NumberRange | null | undefined;
  propertyType: PropertyType;
};

export type Budget = {
  min?: BriefBudget | null | undefined;
  max?: BriefBudget | null | undefined;
};

export type BriefBudget = {
  amount: number;
  formatted: string;
};

export type NumberRange = {
  min?: number | null | undefined;
  max?: number | null | undefined;
};

export type Suburb = {
  coreLogicLocalityId: number;
  name: string;
  isActive: boolean;
  isSelectedByClient?: boolean | null | undefined;
};

export function generatePurchaseBriefSuburbList(
  suburbs: Suburb[],
  purchase: { isFamilyHome: boolean } | undefined
): string {
  let text = "";

  if (!suburbs) return text;

  if (suburbs?.length !== 0) {
    text += `${suburbs
      .filter((suburb) => (purchase?.isFamilyHome ? suburb.isSelectedByClient : suburb.isActive))
      .map((suburb) => suburb.name)
      .join(", ")}`;
  }

  return text;
}

export function generatePurchaseBriefFiltersList(brief: Brief | null | undefined): string {
  let text = "";

  if (!brief) return text;

  if (brief.beds?.min && brief.beds?.min !== 0 && (!brief.beds?.max || brief.beds?.max === 0)) {
    text += `${brief.beds.min}+ bed, `;
  } else if (brief.beds?.min && brief.beds?.min !== 0 && brief.beds?.max && brief.beds?.max !== 0) {
    text += `${brief.beds.min}-${brief.beds.max} bed, `;
  }

  if (brief.baths?.min && brief.baths?.min !== 0 && (!brief.baths?.max || brief.baths?.max === 0)) {
    text += `${brief.baths.min}+ bath, `;
  } else if (brief.baths?.min && brief.baths?.min !== 0 && brief.baths?.max && brief.baths?.max !== 0) {
    text += `${brief.baths.min}-${brief.baths.max} bath`;
  }

  if (brief.cars?.min && brief.cars?.min !== 0 && (!brief.cars?.max || brief.cars?.max === 0)) {
    text += `${brief.cars.min}+ car, `;
  } else if (brief.cars?.min && brief.cars?.min !== 0 && brief.cars?.max && brief.cars?.max !== 0) {
    text += `${brief.cars.min}-${brief.cars.max} car`;
  }

  switch (brief.propertyType) {
    case PropertyType.APARTMENT:
      text += `${propertyTypeMap[PropertyType.APARTMENT].label.toLowerCase()}s`;
      break;

    case PropertyType.COMMERCIAL:
      text += `${propertyTypeMap[PropertyType.COMMERCIAL].label.toLowerCase()}s`;
      break;

    case PropertyType.HOUSE:
      text += `${propertyTypeMap[PropertyType.HOUSE].label.toLowerCase()}s`;
      break;

    case PropertyType.LAND:
      text += `${propertyTypeMap[PropertyType.LAND].label.toLowerCase()}s`;
      break;

    default:
      assertUnreachable(brief.propertyType);
  }

  if (brief.landArea?.min && (!brief.landArea?.max || brief.landArea?.max === 0)) {
    text += ` on ${brief.landArea.min}m2+`;
  } else if (brief.landArea?.max && (!brief.landArea?.min || brief.landArea?.min === 0)) {
    text += ` on -${brief.landArea.max}m2`;
  } else if (brief.landArea?.min && brief.landArea?.max) {
    text += ` on ${brief.landArea.min}m2 - ${brief.landArea.max}m2`;
  }

  if (brief.budget?.min && (!brief.budget?.max || brief.budget?.max?.amount === 0)) {
    text += ` above ${brief.budget.min.formatted}`;
  } else if (brief.budget?.max && (!brief.budget?.min || brief.budget?.min?.amount === 0)) {
    text += ` under ${brief.budget.max.formatted}`;
  } else if (brief.budget?.min && brief.budget?.max) {
    text += ` between ${brief.budget.min.formatted} - ${brief.budget.max.formatted}`;
  }

  return text;
}

export function generatePurchaseBriefDescription(brief: Brief | null | undefined, suburbs: Suburb[]): string {
  let text = "";

  if (!brief) return text;

  switch (brief.propertyType) {
    case PropertyType.APARTMENT:
      text += `${propertyTypeMap[PropertyType.APARTMENT].label}s`;
      break;

    case PropertyType.COMMERCIAL:
      text += `${propertyTypeMap[PropertyType.COMMERCIAL].label}s`;
      break;

    case PropertyType.HOUSE:
      text += `${propertyTypeMap[PropertyType.HOUSE].label}s`;
      break;

    case PropertyType.LAND:
      text += `${propertyTypeMap[PropertyType.LAND].label}s`;
      break;

    default:
      assertUnreachable(brief.propertyType);
  }

  if (brief.landArea?.min && (!brief.landArea?.max || brief.landArea?.max === 0)) {
    text += ` on ${brief.landArea.min}sqm+`;
  } else if (brief.landArea?.max && (!brief.landArea?.min || brief.landArea?.min === 0)) {
    text += ` on -${brief.landArea.max}sqm`;
  } else if (brief.landArea?.min && brief.landArea?.max) {
    text += ` on ${brief.landArea.min}sqm-${brief.landArea.max}sqm`;
  }

  if (brief.budget?.min && (!brief.budget?.max || brief.budget?.max?.amount === 0)) {
    text += ` above ${brief.budget.min.formatted}`;
  } else if (brief.budget?.max && (!brief.budget?.min || brief.budget?.min?.amount === 0)) {
    text += ` under ${brief.budget.max.formatted}`;
  } else if (brief.budget?.min && brief.budget?.max) {
    text += ` between ${brief.budget.min.formatted}-${brief.budget.max.formatted}`;
  }

  if (suburbs?.length !== 0) {
    text += ` in ${suburbs
      .filter((suburb) => suburb.isActive)
      .map((suburb) => suburb.name)
      .join(", ")}`;
  }

  if (brief.beds?.min && brief.beds?.min !== 0 && (!brief.beds?.max || brief.beds?.max === 0)) {
    text += ` with ${brief.beds.min}+ bedrooms`;
  } else if (brief.beds?.min && brief.beds?.min !== 0 && brief.beds?.max && brief.beds?.max !== 0) {
    text += ` with ${brief.beds.min}-${brief.beds.max} bedrooms`;
  }

  text = text.concat(".");

  return text;
}
