import * as Styled from "./timeline.styles";
import { Event, EventProps } from "./timeline.constants";
import { SpaceBetween, Margin } from "../core/spacing.styles";
import { Stack } from "../stack/stack.styles";
import { SingleLine, TimelineIcon } from "./timeline.styles";
import { lightGrey, lightPink, tan } from "../core/colours.styles";
import { Divider } from "../divider/divider.styles";
import SearchTrackerTimeline from "./search-tracker-timeline";
import { PStyledSecondary } from "../core/typography.styles";
import { PropertyCardList } from "@mc/common/blocks/property-cards";
import PurchaseTrackerTimeline from "./purchase-tracker-timeline";
import { useNavigate } from "react-router-dom";
import { GamePlanEventType, LoanAdjustmentType } from "@mc/common/gql/types";
import { gamePlanPurchaseTypeMap, loanAdjustmentTypeMap, renovationTypeMap } from "@mc/common/utils/enum-maps";
import { MdOutlinePayments, MdOutlineConstruction, MdOutlineSell } from "react-icons/md";

const timelineEventTitleSwitch = (event: Event) => {
  switch (event.type) {
    case GamePlanEventType.PROPERTY_PURCHASE:
      return `${event.property?.budget.formatted} ${
        event.property?.purchaseType ? gamePlanPurchaseTypeMap[event.property.purchaseType].alternativeLabel : "N/A"
      }`;
    case GamePlanEventType.PROPERTY_SALE:
      return `${event.sale?.price.formatted} Sell`;
    case GamePlanEventType.PROJECT:
      return `${event.project?.budgetWithCPI?.formatted ?? event.project?.budget.formatted} ${
        event.project?.type ? renovationTypeMap[event.project.type].label : "N/A"
      }`;
    case GamePlanEventType.LOAN_ADJUSTMENT: {
      switch (event.loanAdjustment?.type) {
        case LoanAdjustmentType.OFFSET:
          return `${event.label} • ${event.loanAdjustment?.fund.formatted} ${
            loanAdjustmentTypeMap[event.loanAdjustment.type].label
          } til ${event.endAt?.year}`;
        case LoanAdjustmentType.PREPAYMENT:
          return `${event.label} • ${event.loanAdjustment?.fund.formatted} ${
            loanAdjustmentTypeMap[event.loanAdjustment.type].label
          }`;
      }
    }
  }
};

const timelineIconSwitch = (event: Event): JSX.Element => {
  switch (event.type) {
    case GamePlanEventType.PROPERTY_PURCHASE:
      return <MdOutlinePayments size={24} />;
    case GamePlanEventType.PROPERTY_SALE:
    case GamePlanEventType.LOAN_ADJUSTMENT:
      return <MdOutlineSell size={24} />;
    case GamePlanEventType.PROJECT:
      return <MdOutlineConstruction size={24} />;
    default:
      return <MdOutlinePayments size={24} />;
  }
};

const timelineIconBackgroundSwitch = (event: Event) => {
  switch (event.type) {
    case GamePlanEventType.PROPERTY_PURCHASE:
      return lightPink;
    case GamePlanEventType.PROPERTY_SALE:
    case GamePlanEventType.LOAN_ADJUSTMENT:
      return lightGrey;
    case GamePlanEventType.PROJECT:
      return tan;
    default:
      return lightGrey;
  }
};

const TimelineEvent = ({ event, onClick, isAdmin = false }: EventProps) => {
  const navigate = useNavigate();

  const hasTopResults = (event.purchase?.topResults ?? []).length > 0;

  return (
    <>
      {event.type === GamePlanEventType.LOAN_ADJUSTMENT ? (
        <SingleLine onClick={() => onClick?.(event)}>{timelineEventTitleSwitch(event)}</SingleLine>
      ) : (
        <Styled.TimelineEventContainer>
          <Styled.TimelineEventMainCard>
            <SpaceBetween onClick={() => onClick?.(event)}>
              <Stack spacing={4}>
                <Styled.TimelineEventSmallTitle>{event.label}</Styled.TimelineEventSmallTitle>
                <Styled.TimelineEventTitle>{timelineEventTitleSwitch(event)}</Styled.TimelineEventTitle>
              </Stack>
              <TimelineIcon colour={timelineIconBackgroundSwitch(event)}>{timelineIconSwitch(event)}</TimelineIcon>
            </SpaceBetween>
          </Styled.TimelineEventMainCard>
          {event.purchase?.stages && event.type === GamePlanEventType.PROPERTY_PURCHASE && (
            <>
              <Divider />
              <SearchTrackerTimeline
                stages={event.purchase?.stages}
                purchaseId={event.purchase._id}
                isAdmin={isAdmin}
                defaultExpanded={!hasTopResults}
              />
            </>
          )}
        </Styled.TimelineEventContainer>
      )}

      {(event.purchase?.topResults?.length ?? 0) > 0 && (
        <>
          <PStyledSecondary marginBottom={12}>Top result</PStyledSecondary>

          <Margin bottom={4}>
            <PropertyCardList
              thumbnail={event.purchase?.topResults?.[0].details.images.defaultImage.mediumPhotoUrl}
              addressFormatted={event.purchase?.topResults?.[0].details.location.singleLine ?? ""}
              isCompact
              onClick={() => {
                if (isAdmin) {
                  return false;
                } else {
                  navigate(`/purchase/${event.purchase?._id}/property/${event.purchase?.topResults?.[0].details.id}`);
                }
              }}
            />
          </Margin>

          {event.purchase && event.purchase.topResults && (
            <PurchaseTrackerTimeline
              stages={event.purchase.topResults[0].stages}
              saleOfferType={event.purchase.topResults[0].saleOfferType}
              purchaseId={event.purchase._id}
              coreLogicPropertyId={event.purchase.topResults[0].details.id}
              propertyId={event.purchase.topResults[0]._id}
              isAdmin={isAdmin}
            />
          )}
        </>
      )}
    </>
  );
};

export default TimelineEvent;
