import { useQuery } from "@apollo/client";
import { GetGamePlanClientQuery, GetGamePlanClientQueryVariables } from "@mc/common/gql/types";
import { useUser } from "user/user.hooks";
import { GET_GAME_PLAN_CLIENT } from "./game-plan.graphql";

export default function useGamePlan(args?: { onCompleted?: (data: GetGamePlanClientQuery) => void }) {
  const { onCompleted } = args ?? {};

  const { user } = useUser();

  const { data, loading } = useQuery<GetGamePlanClientQuery, GetGamePlanClientQueryVariables>(GET_GAME_PLAN_CLIENT, {
    variables: { clientId: user?._id! },
    skip: !user,
    onCompleted,
  });

  return {
    loading,
    gamePlan: data?.gamePlan,
    profile: data?.client.profile,
  };
}
