import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonLink } from "@mc/components/buttons";
import { Margin } from "@mc/components/core/spacing.styles";
import Footer from "shared/layout/footer/footer";
import Header from "shared/layout/header/header";
import { LayoutMainStyled, LayoutContentStyled } from "./layout.styles";
import { PINK, LayoutProps } from "./layout.constants";
import { Container } from "@mc/components/grid/grid.styles";
import GetInTouchFooter from "shared/footer/get-in-touch-footer";
import { MdArrowBack } from "react-icons/md";

export { PINK };

const Layout = ({
  children,
  backgroundColor,
  header,
  isNormalFlowingPage = false,
  hasGetInTouchFooter = false,
  hasFooter = true,
  paddingTop,
  banner,
}: LayoutProps) => {
  return (
    <LayoutMainStyled backgroundColor={backgroundColor} isNormalFlowingPage={isNormalFlowingPage}>
      <Header isNormalFlowingPage={isNormalFlowingPage} {...header} />
      <div>
        {banner && banner}
        <LayoutContentStyled paddingTop={paddingTop}>{children}</LayoutContentStyled>
      </div>
      <div>
        {hasGetInTouchFooter && <GetInTouchFooter />}
        {hasFooter && <Footer />}
      </div>
    </LayoutMainStyled>
  );
};

export default Layout;

export const LayoutContainerFluid = ({ children }: { children: ReactNode }) => <Container>{children}</Container>;

type NavigationBarProps = {
  label?: string;
  destination?: string;
};
export const NavigationBar = ({ label, destination }: NavigationBarProps) => {
  const navigate = useNavigate();

  return (
    <Margin bottom={40}>
      <ButtonLink
        type="button"
        $hideUnderline
        hasLeftIcon
        onClick={() => (destination ? navigate(destination) : navigate(-1))}
      >
        <MdArrowBack />
        {label ?? "Back"}
      </ButtonLink>
    </Margin>
  );
};
