import { gql } from "@apollo/client";

export const CAPITAL_POSITION_SUMMARY_FRAGMENT = gql`
  fragment CapitalPositionSummaryFragment on Daisy {
    _id
    summary {
      _id
      ifSoldRoi {
        formatted
      }
      assumedBankAppraisal {
        formatted
      }
      assumedMarketAppraisal {
        formatted
      }
      debt {
        formatted
      }
      totalEquity {
        formatted
      }
      totalAvailableEquityToRedraw {
        formatted
      }
      lvr {
        formatted
      }
      fundsHeldInOffset {
        formatted
      }
      ifSoldFundsAvailable {
        formatted
      }
    }
  }
`;
