import { gql } from "@apollo/client";

export const PURCHASE_CASH_FLOW_SUMMARY_DAISY_FRAGMENT = gql`
  fragment PurchaseCashFlowSummaryDaisyFragment on Daisy {
    _id
    summary {
      _id
      year
      rentReceived {
        formatted
      }
      monthlyRentReceived: rentReceived(frequency: MONTHLY) {
        formatted
      }
      holdingCost {
        formatted
      }
      monthlyHoldingCost: holdingCost(frequency: MONTHLY) {
        formatted
      }
      loanCost {
        formatted
      }
      monthlyLoanCost: loanCost(frequency: MONTHLY) {
        formatted
      }
      assumedGrossIncomeBest {
        formatted
      }
      monthlyAssumedGrossIncomeBest: assumedGrossIncomeBest(frequency: MONTHLY) {
        formatted
      }
      wallet {
        formatted
      }
      monthlyWallet: wallet(frequency: MONTHLY) {
        formatted
      }
      assumedGrossIncomeWorst {
        formatted
      }
      monthlyAssumedGrossIncomeWorst: assumedGrossIncomeWorst(frequency: MONTHLY) {
        formatted
      }
    }
  }
`;
