import { Modal } from "@mc/components/modal/modal";
import { useQuery } from "@apollo/client";
import { GET_TIMELINE_EVENTS } from "./timeline.graphql";
import CashFlowProperty from "@mc/common/blocks/daisy/cash-flow-property/cash-flow-property";
import PurchaseDetails from "@mc/common/blocks/purchase/purchase-details/purchase-details";
import { H1, PStyled } from "@mc/components/core/typography.styles";
import ProjectDetails from "@mc/common/blocks/purchase/project-details/project-details";
import { Bouncer } from "@mc/components/bouncer/bouncer";
import { Stack } from "@mc/components/stack/stack.styles";
import { GetTimelineEventsQuery } from "@mc/common/gql/types";

type Props = {
  selectedEventId: string | null;
  close: () => void;
  optionId?: string;
};

const TimelinePurchaseModal = ({ selectedEventId, close, optionId }: Props) => {
  const { data, loading } = useQuery<GetTimelineEventsQuery>(GET_TIMELINE_EVENTS, { skip: !selectedEventId });

  const selectedOption = optionId
    ? data?.me.gamePlan?.options.find((option) => option._id === optionId)
    : data?.me.gamePlan?.chosenOption;

  const selectedEvent = selectedOption?.events.find((event) => event._id === selectedEventId);

  if (loading)
    return (
      <Modal isVisible close={close}>
        <Bouncer />
      </Modal>
    );

  return (
    <Modal isVisible close={close}>
      <Stack spacing={40}>
        <H1>Purchase brief</H1>

        <PStyled>{selectedEvent?.property?.description}</PStyled>

        <PurchaseDetails property={selectedEvent?.property} />

        <CashFlowProperty
          daisy={data?.me.gamePlan?.options.find((option) => option._id === optionId)?.daisy}
          gamePlanEvent={selectedEvent}
        />

        {selectedEvent?.property?.firstProjectEvent && (
          <ProjectDetails event={selectedEvent.property.firstProjectEvent} daisy={selectedOption?.daisy} />
        )}
      </Stack>
    </Modal>
  );
};

export default TimelinePurchaseModal;
