import { gql } from "@apollo/client";
import { DAISY_PURCHASE_COSTS_FRAGMENT } from "../../daisy/purchase-costs/purchase-costs.gql";

export const HOME_PURCHASE_DETAILS_PROFILE_FRAGMENT = gql`
  fragment HomePurchaseDetailsProfileFragment on Profile {
    _id
    services
    isFamilyHomePurchasedFirst
    personalInformation {
      everOwnedProperty
    }
    familyHome {
      state
      purchaseAmount
      purchaseYear
      propertyType
      isRenovationRequired
    }
  }
`;

export const HOME_PURCHASE_DETAILS_CLIENT_FRAGMENT = gql`
  fragment HomePurchaseDetailsClientFragment on Client {
    _id
    isCitizenOrPR
  }
`;

export const PURCHASE_COSTS = gql`
  query PurchaseCosts(
    $state: AustralianStates!
    $purchaseAmount: Int!
    $isCitizenOrPR: Boolean!
    $everOwnedProperty: Boolean!
    $propertyType: PropertyType!
    $purchaseYear: Int
  ) {
    daisyPurchaseCosts(
      state: $state
      purchaseAmount: $purchaseAmount
      isCitizenOrPR: $isCitizenOrPR
      everOwnedProperty: $everOwnedProperty
      depositPercent: 20
      propertyType: $propertyType
      purchaseYear: $purchaseYear
    ) {
      ...DaisyPurchaseCostsFragment
    }
  }
  ${DAISY_PURCHASE_COSTS_FRAGMENT}
`;
