import { gql } from "@apollo/client";

export const PURCHASE_PROJECT_DETAILS_EVENT_FRAGMENT = gql`
  fragment PurchaseProjectDetailsEventFragment on GamePlanEvent {
    _id
    label
    startAt {
      monthAndYear: formatted(format: "MMM YYYY")
      year: formatted(format: "YYYY")
    }
    project {
      _id
      type
      budget {
        formatted
      }
      budgetWithCPI {
        formatted
      }
      depositAmount {
        formatted
      }
      assumedROI {
        formatted
      }
      loan {
        _id
        originalAmount {
          formatted
        }
      }
      postRenoIncreaseValue {
        formatted
      }
    }
  }
`;

export const PURCHASE_PROJECT_DETAILS_DAISY_FRAGMENT = gql`
  fragment PurchaseProjectDetailsDaisyFragment on Daisy {
    _id
    properties {
      _id
      propertyName
      year
      marketValue {
        formatted
      }
      rentReceived {
        formatted
      }
    }
  }
`;
