import { gql } from "@apollo/client";

export const PURCHASE_LOAN_ADJUSTMENT_DETAILS_FRAGMENT = gql`
  fragment PurchaseLoanAdjustmentDetailsFragment on GamePlanEvent {
    _id
    startAt {
      year: formatted(format: "YYYY")
    }
    endAt {
      year: formatted(format: "YYYY")
    }
    label
    loanAdjustment {
      _id
      type
      propertyId
      fund {
        formatted
      }
    }
  }
`;

export const PURCHASE_LOAN_ADJUSTMENT_DAISY_FRAGMENT = gql`
  fragment PurchaseLoanAdjustmentDaisyFragment on Daisy {
    _id
    properties {
      _id
      propertyName
      year
      debt {
        formatted
      }
    }
  }
`;
