import { SwotOpportunity, SwotStrength, SwotThreat, SwotWeakness } from "@mc/common/gql/types";

export type SwotValue = {
  enumValue: SwotStrength | SwotWeakness | SwotOpportunity | SwotThreat;
  content: string;
};

type SwotGroup = {
  group: string;
  values: SwotValue[];
};

export type Swot = {
  type: "strength" | "weakness" | "opportunity" | "threat";
  groups: SwotGroup[];
};

export const swot: Swot[] = [
  {
    type: "strength",
    groups: [
      {
        group: "Block",
        values: [
          {
            enumValue: SwotStrength.STRENGTH_FLAT_BLOCK,
            content: "Flat block",
          },
          {
            enumValue: SwotStrength.STRENGTH_REGULAR_SHAPE,
            content: "Regular shape",
          },
          {
            enumValue: SwotStrength.STRENGTH_LAND_SIZE,
            content: "Land size meets our preferred requirements",
          },
          {
            enumValue: SwotStrength.STRENGTH_CORNER_BLOCK,
            content: "Corner block",
          },
          {
            enumValue: SwotStrength.STRENGTH_HOUSE_FRONT_POSITION,
            content: "House is positioned to the front of the block",
          },
          {
            enumValue: SwotStrength.STRENGTH_DUAL_ACCESS,
            content: "Dual access",
          },
          {
            enumValue: SwotStrength.STRENGTH_EASEMENT_FLOOD_FREE,
            content: "Easement and flood overlay free",
          },
          {
            enumValue: SwotStrength.STRENGTH_TWO_SEPARATE_ASSETS,
            content:
              "Two separately titled assets on a single block that can be sold independently, however, combined both produce significant yield potential",
          },
        ],
      },
      {
        group: "Aspect",
        values: [
          {
            enumValue: SwotStrength.STRENGTH_MAXIMISE_NATURAL_LIGHT,
            content: "Living spaces are orientated to maximise natural light",
          },
          {
            enumValue: SwotStrength.STRENGTH_NORTH_EAST_FACING,
            content: "The block is North-East facing to the rear",
          },
          {
            enumValue: SwotStrength.STRENGTH_NORTH_WEST_FACING,
            content: "The block is North-West facing to the rear",
          },
          {
            enumValue: SwotStrength.STRENGTH_NORTH_FACING,
            content: "The block is North facing to the rear",
          },
          {
            enumValue: SwotStrength.STRENGTH_EAST_FACING,
            content: "The block is East facing to the rear",
          },
          {
            enumValue: SwotStrength.STRENGTH_SOUTH_EAST_FACING,
            content: "The block is South-East facing to the rear",
          },
          {
            enumValue: SwotStrength.STRENGTH_SOUTH_WEST_FACING,
            content: "The block is South-West facing to the rear",
          },
          {
            enumValue: SwotStrength.STRENGTH_SOUTH_FACING,
            content: "The block is South facing to the rear",
          },
          {
            enumValue: SwotStrength.STRENGTH_WEST_FACING,
            content: "The block is West facing to the rear",
          },
        ],
      },
      {
        group: "Location",
        values: [
          {
            enumValue: SwotStrength.STRENGTH_STREET_HIGH_SIDE,
            content: "On the high side of the street",
          },
          {
            enumValue: SwotStrength.STRENGTH_TWO_KM_TRAIN_STATION,
            content: "Less than 2km to the closest train station",
          },
          {
            enumValue: SwotStrength.STRENGTH_TWO_KM_SHOPS,
            content: "Less than 2km to the closest shops",
          },
          {
            enumValue: SwotStrength.STRENGTH_ELEVATED_OUTLOOKS,
            content: "The street offers elevated outlooks",
          },
          {
            enumValue: SwotStrength.STRENGTH_CONVENIENT_LOCATION,
            content: "Location is highly convenient",
          },
          {
            enumValue: SwotStrength.STRENGTH_QUIET_STREET,
            content: "Quiet residential street",
          },
          {
            enumValue: SwotStrength.STRENGTH_PREMIER_STREET,
            content: "One of the premier streets in this suburb",
          },
          {
            enumValue: SwotStrength.STRENGTH_FLAT_LOCATION,
            content: "Where the property is located on the street, it is flat",
          },
          {
            enumValue: SwotStrength.STRENGTH_PLENTY_STREET_PARKING,
            content: "Plenty of street parking",
          },
          {
            enumValue: SwotStrength.STRENGTH_APPEALING_STREETSCAPE,
            content: "Appealing streetscape",
          },
          {
            enumValue: SwotStrength.STRENGTH_PRIVATE_LOCATION,
            content: "A very private location",
          },
        ],
      },
      {
        group: "Zoning",
        values: [
          {
            enumValue: SwotStrength.STRENGTH_ZONING_HOUSES,
            content: "Zoning allows for a subdivision to create 2+ separate houses STCA",
          },
          {
            enumValue: SwotStrength.STRENGTH_ZONING_DUPLEX,
            content: "Zoning allows for a subdivision to create a duplex STCA",
          },
          {
            enumValue: SwotStrength.STRENGTH_ZONING_TOWNHOUSE,
            content: "Zoning allows for a townhouse development STCA",
          },
          {
            enumValue: SwotStrength.STRENGTH_ZONING_APARTMENT,
            content: "Zoning allows for an apartment development STCA",
          },
          {
            enumValue: SwotStrength.STRENGTH_ZONING_MIXED_USE,
            content: "Zoning allows for a mixed-use development STCA",
          },
          {
            enumValue: SwotStrength.STRENGTH_ZONING_GRANNY_FLAT,
            content: "Zoning allows for a granny flat addition STCA",
          },
          {
            enumValue: SwotStrength.STRENGTH_NO_HIGHRISE_ZONE,
            content:
              "The property is located in a zone that currently does not allow for immediately located high rise developments",
          },
        ],
      },
      {
        group: "Asset",
        values: [
          {
            enumValue: SwotStrength.STRENGTH_STRUCTUALLY_SOUND,
            content: "Presents as structurally sound",
          },
          {
            enumValue: SwotStrength.STRENGTH_SOLID_FOUNDATIONS,
            content: "Foundations appear to be solid",
          },
          {
            enumValue: SwotStrength.STRENGTH_WELL_PRESENTED_GARDENS,
            content: "Well presented, established gardens that enhance the properties overall appeal",
          },
          {
            enumValue: SwotStrength.STRENGTH_IMMACULATELY_RENOVATED,
            content: "Immaculately renovated",
          },
          {
            enumValue: SwotStrength.STRENGTH_BEDROOM_FANS,
            content: "Fans in bedrooms",
          },
          {
            enumValue: SwotStrength.STRENGTH_CENTRAL_HEATING_COOLING,
            content: "Central heating/cooling throughout",
          },
          {
            enumValue: SwotStrength.STRENGTH_FLOORPLAN_SUITS_REQUIREMENTS,
            content: "Functional floorplan suited to modern living requirements",
          },
          {
            enumValue: SwotStrength.STRENGTH_ORIGINAL_CHARACTER_MAINTAINED,
            content: "Original character of the property has been restored/maintained",
          },
          {
            enumValue: SwotStrength.STRENGTH_FLOORPLAN_MAXIMISES_LIGHTING,
            content: "The floorplan has been designed to maximise natural light in the living spaces primarily",
          },
          {
            enumValue: SwotStrength.STRENGTH_OPEN_FLOORPLAN,
            content:
              "Open floor plan with living, dining and kitchen zones connecting to the outside alfresco zone and garden",
          },
          {
            enumValue: SwotStrength.STRENGTH_BEDROOMS_AT_FRONT,
            content: "Bedrooms are at the front of the house, followed by the services out to the living zones",
          },
          {
            enumValue: SwotStrength.STRENGTH_OFFICE_WORK_SPACE,
            content: "There is an office/workspace which is appealing to the work from home demographic",
          },
          {
            enumValue: SwotStrength.STRENGTH_TWO_LIVING_SPACES,
            content: "There are two separate living spaces which is ideal for a 4 bedroom home",
          },
          {
            enumValue: SwotStrength.STRENGTH_ADEQUATE_BEDROOM_SIZES,
            content:
              "Bedroom room sizes adequate enough to house a queen size bed (King in the master) with two side tables on either side",
          },
          {
            enumValue: SwotStrength.STRENGTH_KITCHEN_WITH_VISION,
            content: "Kitchen orientated with a 180-degree vision to the rear yard and internal living zones",
          },
          {
            enumValue: SwotStrength.STRENGTH_FRONT_FENCE,
            content: "Front fence (or similar) providing security and privacy for families and pets",
          },
          {
            enumValue: SwotStrength.STRENGTH_NEUTRAL_DESIGN_AESTHETIC,
            content:
              "A design aesthetic that is neutral, not jarring, and one that will stand the test of time and not date",
          },
          {
            enumValue: SwotStrength.STRENGTH_HERO_HOME_CHARACTER,
            content:
              "The character of the home is the hero and aesthetically not jarring for the regions architecture and demographic requirements",
          },
          {
            enumValue: SwotStrength.STRENGTH_ADEQUATE_STORAGE,
            content: "The property has adequate storage suitable for the size of the home",
          },
          {
            enumValue: SwotStrength.STRENGTH_GOOD_OUTDOOR_SHED,
            content: "Good size outdoor shed",
          },
          {
            enumValue: SwotStrength.STRENGTH_GRASSED_BACKYARD,
            content: "A grassed backyard suitable for families and pets to enjoy",
          },
        ],
      },
      {
        group: "Investment",
        values: [
          {
            enumValue: SwotStrength.STRENGTH_MINIMAL_INVESTMENT,
            content: "Minimal investment required to have the property lease ready",
          },
          {
            enumValue: SwotStrength.STRENGTH_OWNERS_PREFERENCE,
            content: "Owner's preference for a rent back option post-settlement",
          },
          {
            enumValue: SwotStrength.STRENGTH_COSMETIC_RENOVATION,
            content: "Ability to aesthetically improve the property with a cosmetic renovation",
          },
          {
            enumValue: SwotStrength.STRENGTH_EXTEND_LIVING_SPACES,
            content: "Room to extend to create additional living spaces",
          },
          {
            enumValue: SwotStrength.STRENGTH_AVOID_PAYING_PREMIUM,
            content: "A chance to avoid paying a premium through buyer competition with an off-market purchase",
          },
        ],
      },
      {
        group: "POD",
        values: [
          {
            enumValue: SwotStrength.STRENGTH_CITY_VIEWS,
            content: "Gun barrel city views",
          },
          {
            enumValue: SwotStrength.STRENGTH_DISTRICT_VIEWS,
            content: "District views",
          },
          {
            enumValue: SwotStrength.STRENGTH_WATER_VIEWS,
            content: "Water views",
          },
          {
            enumValue: SwotStrength.STRENGTH_MANUFACTURE_CITY_VIEWS,
            content: "Ability to manufacture city views via an extension to the existing property",
          },
          {
            enumValue: SwotStrength.STRENGTH_RUNS_ON_SOLAR,
            content: "Runs on solar and is completely off the grid",
          },
        ],
      },
    ],
  },
  {
    type: "weakness",
    groups: [
      {
        group: "Block",
        values: [
          {
            enumValue: SwotWeakness.WEAKNESS_SLOPING_BLOCK,
            content: "Sloping block",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_IRREGULAR_SHAPED_BLOCK,
            content: "Irregular shaped block",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_LAND_SIZE,
            content: "Land size does not meet our preferred requirements",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_HOUSE_POSITIONED_AWKWARDLY,
            content: "House is positioned awkwardly on the block",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_EASEMENTS_ON_PROPERTY,
            content:
              "There are easements on this property that might impact the ability for future add value opportunities",
          },
        ],
      },
      {
        group: "Aspect",
        values: [
          {
            enumValue: SwotWeakness.WEAKNESS_MINIMAL_NATURAL_LIGHT,
            content: "Living spaces are dark with minimal natural light",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_NORTH_EAST_FACING,
            content: "The block is North-East facing to the rear",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_NORTH_WEST_FACING,
            content: "The block is North-West facing to the rear",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_NORTH_FACING,
            content: "The block is North facing to the rear",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_EAST_FACING,
            content: "The block is East facing to the rear",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_SOUTH_EAST_FACING,
            content: "The block is South-East facing to the rear",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_SOUTH_WEST_FACING,
            content: "The block is South-West facing to the rear",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_SOUTH_FACING,
            content: "The block is South facing to the rear",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_WEST_FACING,
            content: "The block is West facing to the rear",
          },
        ],
      },
      {
        group: "Location",
        values: [
          {
            enumValue: SwotWeakness.WEAKNESS_LOW_SIDE_STREET,
            content: "On the low side of the street",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_TWO_KM_TRAIN_STATION,
            content: "More than 2km to the closest train station",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_TWO_KM_SHOPS,
            content: "More than 2km to the closest shops",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_NO_ELEVATED_OUTLOOKS,
            content: "The street does not offer elevated outlooks",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_THOROUGHFARE_STREET,
            content: "On a thoroughfare street",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_MAIN_ROAD,
            content: "On a main (or busy) road",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_INFERIOR_STREET,
            content: "On an inferior street in this suburb",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_HILLY_STREET,
            content: "The street itself is hilly",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_LACK_STREET_PARKING,
            content: "Lack of street parking available",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_NOT_APPEALING_STREET,
            content: "A street appeal that is not appealing",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_LACK_OF_PRIVACY,
            content: "A lack of privacy from immediate neighbours",
          },
        ],
      },
      {
        group: "Zoning",
        values: [
          {
            enumValue: SwotWeakness.WEAKNESS_ZONING_SEPARATE_HOUSES,
            content: "Zoning does not allow for a subdivision to create 2+ separate houses",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_ZONING_DUPLEX,
            content: "Zoning does not allow for a subdivision to create a duplex",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_ZONING_TOWNHOUSE,
            content: "Zoning does not allow for a townhouse development",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_ZONING_APARTMENT,
            content: "Zoning does not allow for an apartment development",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_ZONING_MIXED_USE,
            content: "Zoning does not allow for a mixed-use development",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_ZONING_GRANNY_FLAT,
            content: "Zoning does not allow for a granny flat addition",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_HERITAGE_OVERLAY_ZONE,
            content: "The property is in a heritage overlay zone",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_CHARACTER_ZONE_OVERLAY,
            content: "The property has a character zone overlay",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_CONSERVATION_PROTECTION_ZONE,
            content: "The property is in a conservation protection overlay zone",
          },
        ],
      },
      {
        group: "Asset",
        values: [
          {
            enumValue: SwotWeakness.WEAKNESS_STRUCTURAL_INTEGRITY_CONCERNS,
            content: "There are concerns for the structural integrity of the dwelling",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_NOTICEABLE_STEP_CRACKING,
            content: "Noticeable step cracking in the brickwork/render",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_OVERGROWN_GARDENS,
            content: "Gardens are overgrown limiting the properties overall appeal",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_HOME_HANDYMAN_RENOVATION,
            content: "Home handyman' renovation",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_LACK_COOLING_HEATING,
            content: "Lack of cooling/heating options in bedrooms",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_NO_CENTRAL_HEATING_COOLING,
            content: "No centrally located heating/cooling",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_DYSFUNCTIONAL_FLOORPLAN,
            content: "Dysfunctional floorplan that is not suited to modern living requirements",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_ORIGINAL_CHARACTER_BASTARDISED,
            content: "Original character of the property has been 'bastardised'",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_FLOORPLAN_NO_LIGHT,
            content: "The floorplan does not maximise natural light in the living spaces",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_CLOSED_FLOORPLAN,
            content: "Closed floor plan segregating the living, dining and kitchen zones",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_UNDESIRABLE_BEDROOM_LOCATIONS,
            content: "Bedrooms are located in less desirable zones of the house",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_NO_OFFICE_WORKPLACE,
            content: "There is no office/workspace or the room to create this",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_ONE_LIVING_SPACE,
            content: "There is only one separate living space which is not desirable for a 4 bedroom home",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_BEDROOM_NOT_ADEQUATE,
            content:
              "Bedroom room sizes are not adequate enough to house a queen size bed (King in the master) with two side tables on either side",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_KITCHEN_NO_VISION,
            content: "The kitchen is orientated with no vision to the rear yard or internal living zones",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_NO_FRONT_FENCE,
            content: "There is no front fence (or similar) providing security and privacy for families and pets",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_JARRING_DESIGN_AESTHETIC,
            content: "The design aesthetic is jarring and one that will date over time",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_LACKS_ADEQUATE_STORAGE,
            content:
              "The property lacks adequate storage solutions suitable for the size of the home with minimal opportunities to add these in the future",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_NO_OUTDOOR_SHED,
            content: "No outdoor shed",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_UNSUITABLE_BACKYARD,
            content: "The backyard is not suitable for families and pets in its current condition",
          },
        ],
      },
      {
        group: "Investment",
        values: [
          {
            enumValue: SwotWeakness.WEAKNESS_SIGNIFICANT_INVESTMENT,
            content: "A significant investment over what we have modelled is required to have the property lease ready",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_TENANT_PAYING_LOW,
            content:
              "The current tenant is paying well below the market rate affecting your cash flow position immediately",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_GREATER_COST,
            content:
              "The cost to aesthetically improve the property is going to be greater than what your game plan allows",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_NO_EXTENSION_ROOM,
            content: "There is no room to extend and create additional living spaces",
          },
          {
            enumValue: SwotWeakness.WEAKNESS_SIGNIFICANT_BUYER_COMPETITION,
            content: "Current market conditions may mean paying a premium because of the significant buyer competition",
          },
        ],
      },
    ],
  },
  {
    type: "opportunity",
    groups: [
      {
        group: "",
        values: [
          {
            enumValue: SwotOpportunity.OPPORTUNITY_BUILD_SECOND_DWELLING,
            content: "Subdivide the block, keep the existing dwelling and build a new second freestanding dwelling",
          },
          {
            enumValue: SwotOpportunity.OPPORTUNITY_BUILD_TWO_DWELLINGS,
            content: "Subdivide block, demolish the existing dwelling and build two new freestanding dwellings",
          },
          {
            enumValue: SwotOpportunity.OPPORTUNITY_BUILD_MULTIPLE_DWELLINGS,
            content:
              "Subdivide the block, keep existing dwelling and build more than one additional new freestanding dwellings",
          },
          {
            enumValue: SwotOpportunity.OPPORTUNITY_BUILD_DUPLEX,
            content: "Subdivide block, demolish the existing house and build a new duplex",
          },
          {
            enumValue: SwotOpportunity.OPPORTUNITY_BUILD_TRIPLEX,
            content: "Subdivide block, demolish the existing house and build a new triplex",
          },
          {
            enumValue: SwotOpportunity.OPPORTUNITY_BUILD_FOUR_TOWNHOUSES,
            content: "Subdivide block, demolish the existing house and build 4 townhouses",
          },
          {
            enumValue: SwotOpportunity.OPPORTUNITY_COSMETIC_RENOVATION,
            content: "A cosmetic renovation of the existing dwelling to minimise initial outlay but maximise yield",
          },
          {
            enumValue: SwotOpportunity.OPPORTUNITY_ADDITIONAL_BEDROOM,
            content: "Reconfigure the existing floor plan to create an additional bedroom",
          },
          {
            enumValue: SwotOpportunity.OPPORTUNITY_ADDITIONAL_BATHROOM,
            content: "Reconfigure the existing floor plan to create an additional bathroom",
          },
          {
            enumValue: SwotOpportunity.OPPORTUNITY_ADDITIONAL_LIVING_ZONE,
            content: "Reconfigure the existing floor plan to create an additional living zone",
          },
          {
            enumValue: SwotOpportunity.OPPORTUNITY_ADDITIONAL_ALL,
            content: "Reconfigure the existing floor plan to create an additional bedroom, bathroom and living zone",
          },
          {
            enumValue: SwotOpportunity.OPPORTUNITY_ADD_GRANNY_FLAT,
            content: "The asset offers the scope to add a granny flat in its current composition",
          },
          {
            enumValue: SwotOpportunity.OPPORTUNITY_INCREASE_FLOOR_SPACE,
            content: "Structural renovation and extension to increase floor space",
          },
          {
            enumValue: SwotOpportunity.OPPORTUNITY_RAISE_EXISTING_HOUSE,
            content:
              "The ability to raise the existing house, build in underneath and duplicate the existing footprint",
          },
          {
            enumValue: SwotOpportunity.OPPORTUNITY_PICK_UP_HOUSE,
            content:
              "The ability to pick up the existing house, move it to the side/rear and allow for an additional dwelling to be built on the same block",
          },
        ],
      },
    ],
  },
  {
    type: "threat",
    groups: [
      {
        group: "",
        values: [
          {
            enumValue: SwotThreat.THREAT_COUNCIL_APPROVAL_REQUIRED,
            content:
              "We have undertaken as much due diligence as possible with regards to the approval process and recommendations surrounding subdivision and the construction style, however, with any project of this nature, council approval is required and certainty isn't provided until development application plans are approved",
          },
          {
            enumValue: SwotThreat.THREAT_ZONING_CHANGE,
            content:
              "Zoning of this property may change without notice in the future and this may impact your ability to execute on any construction projects that may currently be possible",
          },
          {
            enumValue: SwotThreat.THREAT_MATERIAL_LABOUR_COST,
            content:
              "Material and labour cost increases between now and when your potential construction project commences may impact your ROI",
          },
          {
            enumValue: SwotThreat.THREAT_HIGH_MAINTENANCE_EXPENSES,
            content:
              "This property is old and whilst it presents with amazing character and charm, there is the potential for higher than anticipated maintenance expenses",
          },
          {
            enumValue: SwotThreat.THREAT_UNEXPECTED_COSTS,
            content:
              "This purchase comes with a recommendation for a major structural renovation. Whilst we try to anticipate and cater for the unexpected, there is always the risk that unexpected costs are presented once the works begin in areas that can't be identified as risks by a pre-purchase report",
          },
          {
            enumValue: SwotThreat.THREAT_OWNERS_CORPORATION_APPROVAL,
            content:
              "All plans must be approved by the owner's corporation which can be extremely complex, time consuming and financially impactful - without any guarantee of a successful outcome",
          },
        ],
      },
    ],
  },
];
