import gql from "graphql-tag";

const STAGES_FRAGMENT = gql`
  fragment StagesFragment on ShortlistProperty {
    _id
    stages {
      label
      number
      items {
        type
        label
        slug
        status
        published
      }
    }
  }
`;

export const GET_DISTANCE = gql`
  query GetDistance($distanceInput: DistanceInput!) {
    distance(distanceInput: $distanceInput) {
      formatted
    }
  }
`;

export const APPROVE_PURCHASE_TRACKER_STAGE_OR_ITEM = gql`
  mutation ApprovePurchaseTrackerStageOrItem(
    $shortlistPropertyId: String!
    $itemStatus: PurchaseTrackerItemStatus!
    $stageType: PurchaseTrackerStageType
    $itemType: PurchaseTrackerItemType
  ) {
    updatePurchaseTrackerPropertyStageOrItemStatus(
      shortlistPropertyId: $shortlistPropertyId
      itemStatus: $itemStatus
      stageType: $stageType
      itemType: $itemType
    ) {
      ...StagesFragment
    }
  }
  ${STAGES_FRAGMENT}
`;
