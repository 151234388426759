import { gql } from "@apollo/client";

export const CORE_LOGIC_PROPERTY_DETAILS_FRAGMENT = gql`
  fragment CoreLogicPropertyDetailsFragment on PropertyDetails {
    id
    coreAttributes {
      id
      beds
      baths
      carSpaces
      landArea
      propertyType
    }
    additionalAttributes {
      id
      yearBuilt
    }
    location {
      id
      singleLine
    }
    images {
      id
      defaultImage {
        basePhotoUrl
        largePhotoUrl
      }
    }
    lastSales {
      id
      lastSale {
        price {
          formatted
          amount
        }
        contractDate {
          formatted(format: "YYYY-MM-DD")
          alt: formatted(format: "MMMM YYYY")
          year: formatted(format: "YYYY")
        }
      }
    }
  }
`;

export const EXISTING_PROPERTIES_FRAGMENT = gql`
  fragment ExistingPropertiesFragment on Profile {
    _id
    personalInformation {
      everOwnedProperty
    }
    existingProperties {
      _id
      address {
        coordinates {
          latitude
          longitude
        }
        country
        fullAddress
        googlePlaceId
        line1
        line2
        postcode
        state
        suburb
      }
      allowManagedByMilkChocolate
      currentValue
      isSellable
      ownershipPercent
      willingToUseEquity
      equityToUse {
        amount
        formatted
      }
      purchaseAmount
      purchasedAt {
        formatted(format: "YYYY-MM-DD")
      }
      isInvestmentProperty
      expenses {
        amount
        expenseType
        recurrence {
          frequency
        }
      }
      loans {
        currentBalance {
          amount
        }
        currentInterestRate {
          value
          formatted
        }
        originalAmount {
          amount
        }
        originalInterestRate {
          value
          formatted
        }
        originalLoanTermInYears
        lender
        loanType
        nameOfContact
        repaymentType
      }
      income {
        amount
        recurrence {
          frequency
        }
      }
      details {
        ...CoreLogicPropertyDetailsFragment
      }
    }
  }
  ${CORE_LOGIC_PROPERTY_DETAILS_FRAGMENT}
`;

export const SEARCH_CORELOGIC_ADDRESS = gql`
  query SearchCoreLogicAddress($text: String!) {
    searchCoreLogicAddress(type: ADDRESS, text: $text) {
      suggestions {
        propertyId
        suggestion
      }
    }
  }
`;

export const GET_CORELOGIC_PROPERTY_DETAILS_FOR_ADDRESS_SEARCH = gql`
  query GetCoreLogicPropertyDetailsForAddressSearch($propertyId: Int!) {
    coreLogicPropertyDetails(propertyId: $propertyId) {
      ...CoreLogicPropertyDetailsFragment
    }
  }
  ${CORE_LOGIC_PROPERTY_DETAILS_FRAGMENT}
`;
