import { SpaceBetween } from "@mc/components/core/spacing.styles";
import { H3 } from "@mc/components/core/typography.styles";
import { SelectInput } from "@mc/components/inputs/inputs.styles";
import TooltipInfoModal from "@mc/components/tooltips/tooltip-info-modal";
import {
  PurchaseCashFlowDaisyFragmentFragment,
  PurchaseCashFlowSummaryDaisyFragmentFragment,
} from "@mc/common/gql/types";

type Props = {
  yearIndex: number | undefined;
  selectedYear: number;
  setSelectedYear: React.Dispatch<React.SetStateAction<number>>;
  foundDaisyProperty:
    | PurchaseCashFlowDaisyFragmentFragment["properties"][0]
    | PurchaseCashFlowSummaryDaisyFragmentFragment["summary"];
  helpContent: JSX.Element;
};
const CashFlowHeading = ({ yearIndex, selectedYear, setSelectedYear, foundDaisyProperty, helpContent }: Props) => {
  return (
    <SpaceBetween alignItems="baseline" $marginBottom={12}>
      <H3 marginBottom={0}>
        Cash flow <TooltipInfoModal text={helpContent} />
      </H3>
      {yearIndex === undefined && (
        <div>
          <SelectInput value={selectedYear} onChange={(e) => setSelectedYear(Number(e.target.value))}>
            {foundDaisyProperty.year.map((y) => (
              <option key={y} value={y}>
                {y}
              </option>
            ))}
          </SelectInput>
        </div>
      )}
    </SpaceBetween>
  );
};

export default CashFlowHeading;
