import { tan } from "@mc/components/core/colours.styles";
import { mediaUp, rem } from "@mc/components/core/styles";
import styled from "styled-components";

type HeroSectionContainerProps = {
  marginTop?: number;
};

export const HeroSectionContainer = styled.div<HeroSectionContainerProps>`
  background-color: ${tan};
  /* Using negative css value to accomodate for mobile touch refresh */
  margin-top: ${({ marginTop }) => rem(marginTop ?? -128)};
  padding-top: ${rem(64)};
`;

export const HeroImageContainer = styled.div<{ isRegister?: boolean }>`
  background-color: ${tan};
  padding-top: ${rem(64)};

  img {
    display: block;
    height: 150px;
    width: auto;

    ${({ isRegister }) => (isRegister ? mediaUp.lg`height: 288px` : mediaUp.lg`height: 250px`)};
  }
`;
