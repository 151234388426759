import { Props } from "./property-card.constants";
import * as Styled from "./property-card.styles";
import { useImageLoad } from "@mc/components/images/images.hooks";
import ImageCarousel from "@mc/components/image-carousel/image-carousel";
import { PropertyCardIcon } from "./property-card-list.styles";

const PropertyCard = ({
  bedrooms,
  bathrooms,
  carSpaces,
  landSize,
  thumbnail,
  addressFormatted,
  link,
  onClick,
  propertyDetails,
  marginBottom,
  imageDetailTop,
  images = [],
  contentPadding,
  hasBorder,
}: Props) => {
  const { imageHasLoaded } = useImageLoad(thumbnail);

  return (
    <Styled.PropertyCard marginBottom={marginBottom} hasBorder={hasBorder}>
      {images.length === 0 && (
        <Styled.PropertyCardImageContainer onClick={onClick}>
          {imageDetailTop && <Styled.PropertyCardImageDetailTop>{imageDetailTop}</Styled.PropertyCardImageDetailTop>}
          <Styled.PropertyCardImage imageHasLoaded={imageHasLoaded}>
            {thumbnail && <img src={thumbnail} alt="" />}
          </Styled.PropertyCardImage>
        </Styled.PropertyCardImageContainer>
      )}
      {images && <ImageCarousel images={images} />}
      <Styled.PropertyCardContent contentPadding={contentPadding}>
        {!addressFormatted ? null : link ? (
          <Styled.PropertyCardLink to={link}>{addressFormatted}</Styled.PropertyCardLink>
        ) : (
          <Styled.PropertyCardTitle onClick={onClick}>{addressFormatted}</Styled.PropertyCardTitle>
        )}
        {(bedrooms != null || bathrooms != null || carSpaces != null || landSize != null) && (
          <Styled.PropertyCardProps>
            {typeof bedrooms === "number" && (
              <Styled.PropertyCardProp>
                <PropertyCardIcon id="bed" width={24} height={24} />
                {bedrooms}
              </Styled.PropertyCardProp>
            )}
            {typeof bathrooms === "number" && (
              <Styled.PropertyCardProp>
                <PropertyCardIcon id="bath" width={24} height={24} />
                {bathrooms}
              </Styled.PropertyCardProp>
            )}
            {typeof carSpaces === "number" && (
              <Styled.PropertyCardProp>
                <PropertyCardIcon id="car" width={24} height={24} />
                {carSpaces}
              </Styled.PropertyCardProp>
            )}
            {typeof landSize === "number" && (
              <Styled.PropertyCardProp>
                <PropertyCardIcon id="landsize" width={24} height={24} />
                {landSize}m<sup>2</sup>
              </Styled.PropertyCardProp>
            )}
          </Styled.PropertyCardProps>
        )}
        {propertyDetails && <Styled.PropertyCardDetails>{propertyDetails}</Styled.PropertyCardDetails>}
      </Styled.PropertyCardContent>
    </Styled.PropertyCard>
  );
};
export default PropertyCard;
