import { rem } from "@mc/components/core/styles";
import styled from "styled-components";

export const UL = styled.ul`
  display: block;
  margin: ${rem(16)} ${rem(0)};
  padding-left: ${rem(40)};

  li {
    list-style: disc;
  }
`;
