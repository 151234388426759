import * as Styled from "./dropdown-menu.styles";
import { Menu } from "@headlessui/react";
import { ReactNode } from "react";
import { useFloating, flip, shift, offset } from "@floating-ui/react-dom";

export type Options = {
  label: string;
  value?: string;
  isDisabled?: boolean;
  isActive?: boolean;
};

export type OptionsGroup = {
  label: string;
  options: Options[];
};

type Props = {
  options: OptionsGroup[];
  $withBorder?: boolean;
  onSelect: (option: Options) => void;
  icon: ReactNode;
  noBackgroundColor?: boolean;
  shrink?: boolean;
};

const DropDownMenu = ({ options, $withBorder, onSelect, icon, noBackgroundColor, shrink }: Props) => {
  const { refs, floatingStyles } = useFloating({
    middleware: [flip(), shift(), offset(8)],
  });

  return (
    <Styled.MenuContainer $withBorder={$withBorder} shrink={shrink} noBackgroundColor={noBackgroundColor}>
      <Menu as="div">
        <Menu.Button as={Styled.MenuButton} aria-label="Menu" ref={refs.setReference}>
          {icon}
        </Menu.Button>

        <Menu.Items>
          <Styled.Items ref={refs.setFloating} style={floatingStyles}>
            {options.map((group, groupIndex) =>
              group.options.map((option, optionIndex) => (
                <Menu.Item key={option.value} disabled={option.isDisabled}>
                  {() => (
                    <Styled.Item
                      // Add a top border to the first item but not the first group
                      isFirstItem={groupIndex !== 0 && optionIndex === 0}
                      disabled={option.isDisabled}
                      onClick={() => {
                        if (!option.isDisabled) {
                          onSelect(option);
                        }
                      }}
                      isActive={option.isActive ?? false}
                    >
                      <span>{option.label}</span>
                    </Styled.Item>
                  )}
                </Menu.Item>
              ))
            )}
          </Styled.Items>
        </Menu.Items>
      </Menu>
    </Styled.MenuContainer>
  );
};

export default DropDownMenu;
